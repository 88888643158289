import React, { useCallback, useEffect, useRef, useState,  } from "react";
import { observer } from 'mobx-react-lite';
import ContentTop from "../ContentTop/ContentTop";
import appStore from "../../Store/MainStore";
import InfoBlock from "../InfoBlock/InfoBlock";
import { ReactComponent as ArrowSVG } from '../../svg/arrow.svg'
import VerticalCards from "../Card/VerticalCards";
import { useNavigate, useParams } from "react-router-dom";
import useWindowDimensions from "../../utils/Hooks";
import Headers from "../Header/Headers";

const sortItems = [
  { label: 'По охвату', name: 'coverage' },
  // { label: 'По лайкам', name: 'likeCount' },
  // { label: 'По репостам', name: 'shareCount' },
  { label: 'По комментариям', name: 'commentCount' },
  { label: 'По ERR', name: 'erCount' },
]
const filterItems = [
  { label: 'Все', id: 3 },
  { label: 'Постоянные', id: 1 },
  { label: 'Ситуативные', id: 2 },
]

const SortComponent = observer(() => {
  const [isOpen, setIsOpen] = useState(false);
  const refSort = useRef(null);

  useEffect(() => {
    const onClick = e => {
      if (refSort && refSort.current && !refSort.current.contains(e.target) && isOpen) {
        setIsOpen(false);
      }
    }
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [isOpen, refSort]);

  return <div ref={refSort} className="infoblock__sort">
    <div className="infoblock__sort-selected infopage" onClick={() => setIsOpen(!isOpen)}>{sortItems.filter(v => v.name === appStore.infoBlockSort)[0].label} <ArrowSVG /></div>
    {isOpen && <div className="infoblock__sort-items">
      {sortItems.map(item => (<div key={`sort-${item.name}`} className={`infoblock__sort-item ${appStore.infoBlockSort === item.name ? 'infoblock__sort-item_active' : ''}`} onClick={() => { appStore.infoBlockSort = item.name; setIsOpen(false); appStore.SortInfoBlocks(); }}>{item.label}</div>))}
    </div>}

  </div>
})
const FilterComponent = observer(() => {
  return (<div className="infoblock__header-tab">
    {filterItems.map(item => (<div key={`filter-${item.id}`} className={`infoblock__header-tab-item ${appStore.cmVerticalFilter === item.id ? 'infoblock__header-tab-item_active' : ''}`} onClick={() => { appStore.cmVerticalFilter = item.id; appStore.cmChangeFilterSort(); }}>{item.label}</div>))}
  </div>)
})



const Header = () => {
  const { isPhone, isBigPhone } = useWindowDimensions();

  return (
    <>
      <div>
        <div className="infoblock__header">
        <div className="infoblock__header-tab">
            {/* <FilterComponent /> */}
          </div>
          <div className="infoblock__header-sort">
          {isBigPhone ? null : <div className="infoblock__header-sort-title">Сортировать:</div>}
            <div><SortComponent /></div>
          </div>
        </div>
      </div>
    </>)
}

const InfoPage = () => {

  const param = useParams()
  const navigate = useNavigate();
  const fetch = useCallback(async (id) => {
    if (!Number(id)) {
      navigate('/404');
    }
    // start

    const res = await appStore.getInfoBlock(id);
    // finish
    if (res && res.commonStats && res.commonStats.coverageCount) {
      // navigate('/');
    } else if (res && res === 401) {
      navigate('/login');
    } else if (res && res === 404) {
      navigate('/404');
    }
    
  }, []);

  useEffect(() => {
    if (param && param.id) {
    fetch(param.id);
    };
    document.body.style.cursor = 'auto';
  }, [param]);

  if (appStore.isLoading) {
    return null;
  }

  console.log("ttttt", param)
  

  return (<>
    <Headers title="Инфоповоды"></Headers>
    <ContentTop  isLegend />
    <div className="container-all">
      <InfoBlock infotitle={true} btn={true} title="Инфоповоды" infomob={true}  header={<Header/>} >
        <VerticalCards />
      </InfoBlock>
    </div>
  </>);
}

export default observer(InfoPage, FilterComponent);