import React from "react";
import CardBordered from "../Card/CardBordered";
import { ReactComponent as ExternalLinkSVG } from '../../svg/external-link.svg'

const SNExample = ({
  item
}) => {

  return (
    <CardBordered className={`card-bordered__${item.shortName}`}>
      <div className="card-bordered__social-examples">
        <div><img src={`/social/${item.shortName}.svg`} alt={item.shortName} /></div>
        <div className="card-bordered__title card-bordered__title-flex">{item.title.split('').map((v, index) => (v === ' ' ? <div key={`${item.id}-${index}-example`}>&nbsp;</div> : <div key={`${item.id}-${index}-example`}>{v}</div>))}</div>
        {
          item.examples.map((v, i) => <div className="card-bordered__example-link" key={`example-${item.shortName}-${v.url}-${i}`} onClick={() => window.open(v.url, '_blank', 'noopener,noreferrer')}><div>Пример #{i+1}</div><ExternalLinkSVG /></div>)
        }
      </div>
    </CardBordered>
  )
}

export default SNExample;