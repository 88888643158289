import { observer } from "mobx-react-lite";
import React from "react";
import Slider from "react-slick";
import appStore from "../../Store/MainStore";
import CardBordered from "../Card/CardBordered";
import { ReactComponent as ExternalLinkSVG } from '../../svg/external-link.svg'
import { ReactComponent as ArrowSVG } from '../../svg/slider-arrow.svg'
import CreativeCard from "./CreativeCard";
import useWindowDimensions from "../../utils/Hooks";
import TopPlacement from "./TopPlacement";
import { useNavigate, useParams } from "react-router";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slider-arrow-btn slider-arrow-btn__next ${onClick === null ? 'slider-arrow-btn__disabled' : ''}`}
      onClick={onClick}
    >
      <ArrowSVG />
    </div>
  );
}

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  
  return (
    <div
      className={`slider-arrow-btn slider-arrow-btn__prev ${onClick === null ? 'slider-arrow-btn__disabled' : ''}`}
      onClick={onClick}
    >
      <ArrowSVG />
    </div>
  );
}

const settings = {
  className: "center",
  // centerMode: true,
  // infinite: true,
  slidesToShow: 2,
  swipeToSlide: true,
  // focusOnSelect: true,
  // variableWidth: true,


  infinite: false,
  swipeToSlide: true,
 
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};



const SliderCreatives = () => {
  const { isPhone, isBigPhone } = useWindowDimensions();
  const param = useParams();
  

  return (
    <div className="placement__inner">
      <Slider {...settings }  slidesToShow={isPhone ? 1 : appStore.postCreativ.length > 2 ? 2 : appStore.postCreativ.length} dots={isPhone ? false : false}>
        {
          appStore.postCreativ.map((creative) => (<TopPlacement key={`creative-card-${creative.channel}-${appStore.postCreativ.channel}`} className={`card-bordered__sources`} item={creative} creative={creative}  />))
        }
      </Slider>
      <div onClick={() => appStore.getCreativeDownload('Топ 5 Размещений', param.id) } className="infoblock__title-mobbtn">Скачать все размещения</div>
      
    </div>
  );
}
export default observer(SliderCreatives);













// key={`creative-card-${creative.channel}-${appStore.postCreativ.channel}`} item={creative}