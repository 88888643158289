import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Header from './Components/Header/Headers';
import AuthPage from "./Components/Pages/Auth";
import CommonStatsPage from "./Components/Pages/CommonStats";
import NotFoundPage from "./Components/Pages/NotFound";
import VerticalPage from "./Components/Pages/Vertical";
import { ToastContainer } from 'react-toastify';
import LoaderContainer from "./Components/Loader/LoaderContainer";
import ScrollToTop from "./Components/ScrollTop/ScrollTop";

import './styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import TopCreativesBlock from "./Components/TopCreatives/TopCreativesBlock";
import VerticalCards from "./Components/Card/VerticalCards";
import InfoPage from "./Components/Pages/Info";

const App = () => {

  return (<div className="main">
    {/* <Header title="Тематические блоки" /> */}
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<CommonStatsPage />} />
        <Route path="/info/:id" element={<InfoPage></InfoPage>} />
        <Route path="/newsbreak/:id" element={<VerticalPage />} />
        <Route path="/login" element={<AuthPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
    <ToastContainer />
    <LoaderContainer />
  </div>)
}


export default App;
