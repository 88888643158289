import { observer } from "mobx-react-lite";
import React from "react";
import appStore from "../../Store/MainStore";
import { pluralWords, prettyNumber } from "../../utils/Formatter";
import ParameterIcon from "../Parameter/ParameterIcon";
import ParameterText from "../Parameter/ParameterText";
import ParameterTextCoverage from "../Parameter/ParameterTextCoverage";
import { prettyNumberPostfix } from "../../utils/Formatter";
import ReactTooltip from 'react-tooltip'

const TopCommonStat = () => (
  <>
    <div className="top-content__top"> 

      <ParameterText value={prettyNumberPostfix (appStore.commonStats.publicCount, 0, 10000) } tooltip={appStore.commonStats.publicCount > 10000 ? prettyNumber (appStore.commonStats.publicCount) : '' } text={pluralWords (appStore.commonStats.publicCount, ["Канал", "Канала", "Каналов"])}/> 
      <ParameterText value={prettyNumberPostfix (appStore.commonStats.postCount, 0, 100000) } tooltip={appStore.commonStats.postCount > 100000 ? prettyNumber (appStore.commonStats.postCount) : ''} text="Размещений" />
      <ParameterText value={prettyNumberPostfix (appStore.commonStats.creativeCount, 0, 100000) } tooltip={appStore.commonStats.creativeCount > 100000 ? prettyNumber ( appStore.commonStats.creativeCount) : '' } text="Ср. охват поста" /> {/*Креативов */}
    </div>
    <div className="top-content__line"></div>
    <ParameterText value={prettyNumberPostfix (appStore.commonStats.coverageCount, 0, 100000000)} tooltip={appStore.commonStats.coverageCount > 100000000 ? prettyNumber(appStore.commonStats.coverageCount) : '' } text="Текущий охват" isBig />
   
    <div className="top-content__line"></div>
    <div className="top-content__bottom">
      {appStore.commonStats.erCount !== null ? <ParameterIcon key={`top-stat-er`} param={{ value: appStore.commonStats.erCount, icon: 'er', size: 'big' }} /> : null}
      {appStore.commonStats.commentCount !== null ? <ParameterIcon key={`top-stat-comment`} param={{ value: appStore.commonStats.shareCount, icon: 'comments', size: 'big' }} /> : null}
      {appStore.commonStats.likeCount !== null ? <ParameterIcon key={`top-stat-like`} param={{ value: appStore.commonStats.reactions, icon: 'likes', size: 'big' }} /> : null}
      {/* {appStore.commonStats.shareCount !== null ? <ParameterIcon key={`top-stat-share`} param={{ value: appStore.commonStats.shareCount, icon: 'share', size: 'big' }} /> : null} */}
      
     
    </div>
 
  </>
)

export default observer(TopCommonStat);