import React, { useCallback, useEffect, useRef, useState } from "react";
import { observer } from 'mobx-react-lite';

import ContentTop from "../ContentTop/ContentTop";
import appStore from "../../Store/MainStore";
import InfoBlock from "../InfoBlock/InfoBlock";
import VerticalCard from '../Card/VerticalCard';
import Headers from "../Header/Headers";

import { ReactComponent as ArrowSVG } from '../../svg/arrow.svg'
import VerticalCards from "../Card/VerticalCards";
import { useNavigate, useParams } from "react-router-dom";
import useWindowDimensions from "../../utils/Hooks";
import ThemeBlock from "../ThemeBlock/ThemeBlock";
import TopCreativesBlock from "../TopCreatives/TopCreativesBlock";
import CreativeComment from "../TopCreatives/CreativeComment";
import VerticalPage from "./Vertical";
import AuthPage from "./Auth";
import Description from "../InfoBlock/Description";




const sortItems = [
  { label: 'По охвату', name: 'coverage' },
  // { label: 'По лайкам', name: 'likeCount' },
  // { label: 'По репостам', name: 'shareCount' },
  { label: 'По комментариям', name: 'replies' },
  { label: 'По ERR', name: 'err' },
]
const filterItems = [
  { label: 'Все', id: 3 },
  { label: 'Постоянные', id: 1 },
  { label: 'Ситуативные', id: 2 },
]
const SortComponent = observer(() => {
  const [isOpen, setIsOpen] = useState(false);
  const refSort = useRef(null);

  useEffect(() => {
    const onClick = e => {
      if (refSort && refSort.current && !refSort.current.contains(e.target) && isOpen) {
        setIsOpen(false);
      }
    }
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [isOpen, refSort]);

  return <div ref={refSort} className="infoblock__sort">
    <div className="infoblock__sort-selected" onClick={() => setIsOpen(!isOpen)}>{sortItems.filter(v => v.name === appStore.themeBlockSort )[0].label} <ArrowSVG /></div>
    {isOpen && <div className="infoblock__sort-items">
      {sortItems.map(item => (<div key={`sort-${item.name}`} className={`infoblock__sort-item ${appStore.themeBlockSort  === item.name ? 'infoblock__sort-item_active' : ''}`} 
      onClick={() => { appStore.themeBlockSort  = item.name; setIsOpen(false); appStore.SortThemeBlocks(); }}>{item.label}</div>))}
    </div>}

  </div>

  
})

const FilterComponent = observer(() => {
  return (<div className="infoblock__header-tab">
    {filterItems.map(item => (<div key={`filter-${item.id}`} className={`infoblock__header-tab-item ${appStore.cmVerticalFilter === item.id ? 'infoblock__header-tab-item_active' : ''}`} onClick={() => { appStore.cmVerticalFilter = item.id; appStore.cmChangeFilterSort(); }}>{item.label}</div>))}
  </div>)
})

const Header = () => {
  const { isPhone, isBigPhone } = useWindowDimensions();

  return (<div className="infoblock__header">
    <div className="infoblock__header-sort">
      {isPhone ? null :  <div className="infoblock__header-sort-title">Сортировать:</div>}
      
      <div><SortComponent /></div>
    </div>
    {/* <div className="infoblock__header-tab">
      <FilterComponent />
    </div> */}
  </div>)
}

const CommonStatsPage = () => {

  const navigate = useNavigate();
  const param = useParams()

  console.log('kkkkk',param)

  const fetch = useCallback(async () => {
    const res = await appStore.getThemeBlocksData();
    // start
    const res2 = await appStore.getTotalStats();
    // const res3 = await appStore.getInfoBlock();
    // const res4 = await appStore.getDetailsBlock();
    // finish
    if (res && res.commonStats && res.commonStats.coverageCount) {
      // navigate('/');
    } else if (res && res === 401) {
      navigate('/login');
    }
  }, []);

  

  useEffect(() => {
    fetch()
    document.body.style.cursor = 'auto';
  }, []);

  if (appStore.isLoading) {
    return null;
  }

  return (<>
   <Headers title="Teматические блоки"></Headers>
    <ContentTop pagethemeblock={true} isLegend />
    <div className="container-all">
      <InfoBlock title="Тематические блоки" header={<Header />} isTwoRow className="verticals-block">
          <ThemeBlock></ThemeBlock>
          {/* <VerticalCards /> */}
      </InfoBlock>


    </div>
  </>);
}

export default observer(CommonStatsPage);