export const SocialNetworks = [
  { id: 2, title: 'Вконтакте', shortName: 'vk', color: '#0760A1' },
  { id: 1, title: 'Одноклассники', shortName: 'ok', color: '#AF5C0F' },
  { id: 5, title: 'Facebook', shortName: 'fb', color: '#5200A5' },
  { id: 4, title: 'YouTube', shortName: 'yt', color: '#D12B2B' },
  { id: 3, title: 'Telegram', shortName: 'tg', color: '#43B10E' },
  { id: 6, title: 'Яндекс Дзен', shortName: 'zen', color: '#8e0d0d' },
  { id: 7, title: 'Другие', shortName: 'over', color: '#ffffff' },
  { id: 8, title: `Instagram`, shortName: 'insta', color: '#ff69b4' },
  { id: 9, title: `TikTok`, shortName: 'tiktok', color: '#45c6c1' },
  // { id: 9, title: `TikTok`, shortName: 'tiktok', color: '#25F4EE' },
];
// export const SocialNetworks = [
//   { id: 2, title: 'Вконтакте', shortName: 'vk', color: '#14C8FF' },
//   { id: 1, title: 'Одноклассники', shortName: 'ok', color: '#FFBE15' },
//   { id: 5, title: 'Facebook', shortName: 'fb', color: '#8916FF' },
//   { id: 4, title: 'YouTube', shortName: 'yt', color: '#FF4F50' },
//   { id: 3, title: 'Telegram', shortName: 'tg', color: '#9CFC27' },
// ];

export const Gender = {
  1: { label: 'женщины', name: 'genderW' },
  2: { label: 'мужчины', name: 'genderM' },
};

export const GenderAge = [
  { min: null, max: 18, label: 'до 18' },
  { min: 18, max: 25, label: '18-25' },
  { min: 25, max: 45, label: '25-45' },
  { min: 45, max: null, label: '45+' },
];

export const VerticalType = {
  1: 'Постоянная',
  2: 'Ситуативная',
};
