import { observer } from "mobx-react-lite";
import useWindowDimensions from "../../utils/Hooks";
import React from "react";
import appStore from "../../Store/MainStore";
import InfoBlock from "../InfoBlock/InfoBlock";
import SliderSource from "./SliderSource";

const SourcesBlock = () => {

  const { isPhone, isBigPhoneNew } = useWindowDimensions();

  if (appStore.sources && appStore.sources.length ) {
    return <InfoBlock line={true}  title="Источники">
      <div className={`${appStore.sources.length > 2 ? '' : ''}`}>
        <SliderSource />
      </div>
    </InfoBlock>;
  }

  return null;
};

export default observer(SourcesBlock);