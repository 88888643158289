import { observer } from "mobx-react-lite";
import React from "react";
import ReactTooltip from 'react-tooltip';
import appStore from "../../Store/MainStore";
import useWindowDimensions from "../../utils/Hooks";
import InfoBlock from "../InfoBlock/InfoBlock";
import AuditoryGender from "./AuditoryGender";
import AuditoryCityInterest from "./CityInterest";

const AuditoryBlock = () => {
  const { isPhone } = useWindowDimensions();

  if (appStore.detailPage.auditory.city || appStore.detailPage.auditory.gender || appStore.detailPage.auditory.interest) {
    return <InfoBlock title="Аудитория">
      {
        isPhone ?
        <div className="auditory">
          <div className="display-row">
            { appStore.detailPage.auditory.gender && <AuditoryGender title='Пол и возраст' data={appStore.detailPage.auditory.gender} cnPostfix="-city"/> }
          </div>
          <div className="display-row display-row-height display-row-second">
            { appStore.detailPage.auditory.city && <AuditoryCityInterest title='Города' data={appStore.detailPage.auditory.city} cnPostfix="-city"/> }
            { appStore.detailPage.auditory.interest && <AuditoryCityInterest title='Интересы' data={appStore.detailPage.auditory.interest} cnPostfix="-interest" /> }
          </div>
        </div>
        :
        <div className="auditory">
          { appStore.detailPage.auditory.city && <AuditoryCityInterest title='Города' data={appStore.detailPage.auditory.city} cnPostfix="-city"/> }
          { appStore.detailPage.auditory.gender && <AuditoryGender title='Пол и возраст' data={appStore.detailPage.auditory.gender} cnPostfix="-city"/> }
          { appStore.detailPage.auditory.interest && <AuditoryCityInterest title='Интересы' data={appStore.detailPage.auditory.interest} cnPostfix="-interest" /> }
          <ReactTooltip />
        </div>

      }
    </InfoBlock>;
  }

  return null;
}

export default observer(AuditoryBlock);