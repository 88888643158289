import { Html, OrbitControls } from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DoubleSide, Path, Shape } from "three";
import appStore from "../../Store/MainStore";
import { prettyNumber } from "../../utils/Formatter";

const outerRadius = 37.5;
const innerRadius = 19.5;
const tipPoint = (outerRadius - innerRadius) / 2 + innerRadius;
// var height = 2;
const ExtrudeTest = ({
  color,
  startAngle = 0,
  endAngle = 0,
  height,
  data,
  isAnimateSlice = false,
  onMouseEnter = () => {},
  onClick = () => {},
  changeHover = () => {},
  onReady = () => {},
}) => {
  const ref = useRef(null);
  const [isHover, setIsHover] = useState(false);
  const [endAg, setEndAg] = useState(endAngle);
  const [isAnimate, setIsAnimate] = useState(isAnimateSlice);
  const [heightA, setHeightA] = useState(height);
  const [_ir, setIr] = useState(3);
  const [_outr, setOutr] = useState(15);
  //  useFrame(() => (ref.current.rotation.x = ref.current.rotation.y += 0.01));
  useFrame(() => {
    if (isAnimate) {

      if (_outr < outerRadius) {
        setOutr(_outr+1);
      } else {
        if (_ir < innerRadius) {
          setIr(_ir + 4);
        }
      }
    }
    //   if (endAg > endAngle) {
    //     setIsAnimate(false);
    //     onReady();
    //     if (endAngle > 2 * Math.PI) {
    //       setEndAg(2 * Math.PI);
    //     } else {
    //       setEndAg(endAngle);
    //     }
    //   } else if (endAg === endAngle) {
    //     setIsAnimate(false);
    //   } else {
    //     setEndAg(endAg+0.1);
    //   }
    // }
  // if (endAg === endAngle) {
  //   if (heightA >= height) {
  //     setHeightA(height);
  //   } else {
  //     setHeightA(heightA + 0.3);
  //   }
  // }
  });

  const coords = useMemo(() => {
    const currAngle = (endAngle - startAngle) / 2 + startAngle;
  
    const xP = tipPoint * Math.cos(currAngle) - 15;
    const yP = tipPoint * Math.sin(currAngle) + 14.5;
console.log('x', xP, data.id)
    return ({ x: xP, y: yP });

  }, [endAngle, startAngle]);

  // const arcShape = new CircleGeometry(700, 64);
  const arcShape = useMemo(() => {
    // console.log(_outr)

      const shape = new Shape()
    // .moveTo(0, 0)
    .absarc(0, 0, _outr, startAngle, endAg, false )
    .lineTo(0, 0);

  const _hole = new Path()
    .absarc(0, 0, _ir, startAngle, endAg, false)
    .lineTo(0, 0);

  shape.holes.push(_hole);
  return shape;

    // return new CircleGeometry(700, 64)
  }, [_outr, _ir, startAngle, endAg]);

  const extrudeSettings = {
    depth: heightA,
    bevelEnabled: false,
    bevelThickness: 1,
    bevelSize: 1,
    bevelOffset: 0,
    bevelSegments: 30,
    steps: 1,
    curveSegments: 60,
  }

  // if (data.id === 1) {
  //   return null;
  // }

  return (
    <mesh ref={ref} receiveShadow castShadow onMouseEnter={onMouseEnter} onClick={onClick} onPointerOver={(event) => {
      changeHover(true);
      setIsHover(true);
    }} onPointerOut={(event) => {
      changeHover(false);
      setIsHover(false);
    }} position={[0, 0, -45]}>
      {/* <shapeBufferGeometry attach="geometry" args={[shape]} /> */}
      {/* <shapeBufferGeometry attach="geometry" args={[arcShape]} /> */}
      <extrudeBufferGeometry attach="geometry" args={[arcShape, extrudeSettings]} />
      {/* <meshStandardMaterial color={color} roughness={0}/> */}
      <meshStandardMaterial color={color} side={DoubleSide} />
      {
        false ?
          <Html position={[coords.x, coords.y, 0]} scaleFactor={1}>
            <div className="tooltip-pie">
              <div className="tooltip-pie__num"><span>#</span>{+data.num + 1}</div>
              <div className="tooltip-pie__value">
                {prettyNumber(data.coveragePercent)}%
                <div className="tooltip-pie__pointer"></div>
              </div>
            </div>
          </Html>
         : null
      }
    </mesh>
  )

}

const Slice = ({
  color,
  startAngle = 0,
  endAngle = 0,
  height,
  data,
  isAnimateSlice = false,
  onMouseEnter = () => {},
  onClick = () => {},
  changeHover = () => {},
}) => {
  const ref = useRef(null);
  const [endAg, setEndAg] = useState(0);
  const [isAnimate, setIsAnimate] = useState(isAnimateSlice);
  const [heightA, setHeightA] = useState(height);
  const [_ir, setIr] = useState(innerRadius);
  const [_outr, setOutr] = useState(outerRadius);

  useFrame(() => {
    if (isAnimate) {
      if (endAg < data.endLengthSector) {
      // if (endAg < endAngle) {
        setEndAg(endAg + 0.7);
      } else {
        setEndAg(data.endLengthSector);
        // setEndAg(endAngle);
        setIsAnimate(false);
        // if (_ir < innerRadius) {
        //   setIr(_ir + 4);
        // } else {
        //   setIsAnimate(false);
        // }
      }

      // if (_outr < outerRadius) {
      //   setOutr(_outr+1);
      // } else {
      //   if (_ir < innerRadius) {
      //     setIr(_ir + 4);
      //   }
      // }
    }
  });

  const arcShape = useMemo(() => {
    const shape = new Shape()
      // .moveTo(0, 0)
      // .absarc(0, 0, _outr, 0, data.endLengthSector, false)
      // .absarc(0, 0, _outr, startAngle, endAg, false )
      .absarc(0, 0, _outr, 0, endAg, false)
      .lineTo(0, 0);
    // shape.closePath();

    const _hole = new Path()
      // .moveTo(0, 0)
      // .absarc(0.01, 0.001, _ir, 0, data.endLengthSector, false)
      // .absarc(0, 0, _ir, startAngle, endAg, false)
      // .absarc(0.01, 0.001, _ir, 0, endAg, false)
      // .lineTo(0.01, 0.001);
      .absarc(endAg > Math.PI ? 0 : 0.01, endAg > Math.PI ? 0 : 0.0007, _ir, 0, endAg, false)
      .lineTo(endAg > Math.PI ? 0 : 0.1, endAg > Math.PI ? 0 : 0.001);
    // _hole.closePath();

    shape.holes.push(_hole);
    return shape;
  }, [_outr, _ir, endAg]);

  const extrudeSettings = {
    depth: heightA,
    bevelEnabled: false,
    bevelThickness: 1,
    bevelSize: 1,
    bevelOffset: 0,
    bevelSegments: 30,
    steps: 1,
    curveSegments: 60,
  }

  return (
    <mesh
      ref={ref}
      receiveShadow 
      castShadow 
      onMouseEnter={onMouseEnter} 
      onClick={onClick} 
      onPointerOver={(event) => {
        changeHover(true, data.id);
      }}
      onPointerOut={(event) => {
        changeHover(false, data.id);
      }}
      position={[0, 0, 0]}
      rotation={[0, 0, startAngle]}
    >
      <extrudeBufferGeometry attach="geometry" args={[arcShape, extrudeSettings]} />
      <meshStandardMaterial color={color} side={DoubleSide} />
    </mesh>
  )
}

const Slices = ({ dataSlices, navigate, handleHover }) => {
  const changeCursor = useCallback((isHover) => {
    document.body.style.cursor = isHover ? 'pointer' : 'auto'
  }, []);

  return (
    <>
      {
        dataSlices.map((data, index) => (
          data.coveragePercent && <Slice 
            key={'pie-'+data.id} 
            color={data.color} 
            startAngle={data.startAngle} 
            endAngle={data.lengthSector} 
            height={data.height} 
            data={data}
            isAnimateSlice={true}
            onClick={() => {navigate(`/${data.url}/${data.id}`)}}
            changeHover={(isHover, num) => {changeCursor(isHover); handleHover(num)}}
          />))
      }
    </>
  )
}

const Tip = observer(({ data }) => {
  if (appStore.commonTip.isShow && appStore.commonTip.num === data.id ) {
  // if (true) {
    return (
      <Html position={[data.coords.x, data.coords.y, 5]} scaleFactor={1} >
        <div className="tooltip-pie">
          <div className="tooltip-pie__num"><span>#</span>{+data.num + 1}</div>
          <div className="tooltip-pie__value">
            {prettyNumber(data.coveragePercent)}%
            <div className={`tooltip-pie__pointer ${data.coords.x > 0 ? 'tooltip-pie__pointer-left' : ''}`}></div>
          </div>
        </div>
      </Html>
    )
  }
  return null;
})

const Tips = ({ data }) => {
  const _data = useMemo(() => {
    const tipData = data.map(v => {
      const currAngle = (v.lengthSector - v.startAngle) / 2 + v.startAngle;    
      const xP = tipPoint * Math.cos(currAngle) - 8;
      const yP = tipPoint * Math.sin(currAngle) + 8;

      return ({ ...v, coords: { x: xP, y: yP } })
    });

    return tipData;
  }, [data])

  return _data.map(v => <Tip key={`tip-common-${v.id}`} data={v} />)
}

const Pie3d = ({ isPhone }) => {
  const navigate = useNavigate();

  return (
    <div
      className="pie-diagram"
      onPointerOut={() => appStore.handleCommonTip(false, 0)}
    >
      {
        appStore.commonPie && appStore.commonPie.length ?
        <Canvas
          pixelRatio={window.devicePixelRatio}
          flat
          camera={{
            position: [0, 0, 58],
          }}
          shadows
          gl={{
            antialias: true,
          }}
          mode="concurrent"
        >
          {/* <OrbitControls /> */}
          <ambientLight intensity={0.3} />  
          <directionalLight
              intensity={0.2}
              castShadow
              position={[0, 0, 100]}
              color={'#ffffff'}
              shadow-mapSize-width={2048}
              shadow-mapSize-height={2048}
            />
            <pointLight 
              intensity={0.4}
              position={[-20, 20, 100]}
            />
            {/* <Slice /> */}
            <Slices dataSlices={appStore.commonPie} navigate={navigate} handleHover={(num) => appStore.handleCommonTip(true, num)} />
            <Tips data={appStore.commonPie} />
        </Canvas>

        : null
      }
    </div>
  )
}

export default observer(Pie3d);