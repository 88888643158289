import React from "react";
import CardBordered from "../Card/CardBordered";
import AuditoryItem from "./AuditoryItem";

const AuditoryCityInterest = ({ title, data, cnPostfix }) => {
  return (
    <CardBordered className={`card-bordered__auditory`}>
      <div className="card-bordered__title">{title}</div>
      {
        data.map((v, i) => <AuditoryItem key={`${cnPostfix}-${i}`} item={v} classNamePostfix={cnPostfix} />)
      }
      
    </CardBordered>
  );
}

export default AuditoryCityInterest;