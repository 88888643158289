import { makeAutoObservable } from 'mobx'
import { dateFormat } from '../utils/Formatter';
import { Gender, GenderAge, SocialNetworks } from './constants';
import { toast } from 'react-toastify';
import ThemeBlock from '../Components/ThemeBlock/ThemeBlock';
import { mock12Vertical, mock13Vertical, mockInfoBlock, mockVerticals, themeblocks, mockDetal } from './mock';
import { Navigate } from 'react-router-dom';
const axios = require('axios');

const BASE_URL = process.env.REACT_APP_API_URL;

const sortData = (arr, field) => {
  return arr.sort((a, b) => (a[field] < b[field]) ? 1 : ((b[field] < a[field]) ? -1 : 0));
}


class AppStore {
  authToken = '';
  cmVerticalSort = 'coverageCount';
  cmVerticalFilter = 3;
  cmLegend = [];
  cmVerticals = [
    {
      id: 0,
      name: '',
      image: '',
      color: '',
      coverage: 0,
      public_count: 0,
      post_count: 0,
      replies: 0,
      mean_coverage: 0,
      err: 0.0,
      history: [
        {
          date: 0,
          coverage: 0,
          post_count: 0
        }
      ],
      newsbreaks: [
        {
          id: 0,
          name: '',
          date_start: '',
          date_finishl: '',
          type_id: 0,
          color: '',
          coverage: 0,
          public_count: 0,
          post_count: 0,
          replies: 0,
          mean_coverage: 0,
          err: 0.0,
          progress: 0.0
        }
      ]
    }

  ];
  cmVerticalsAll = [];
  commonPie = [];

  commonStats = {
    coverageCount: 0,
    publicCount: 0,
    postCount: 0,
    creativeCount: 0,
    likeCount: 0,
    shareCount: 0,
    commentCount: 0,
    erCount: 0,
    verticals: 0,
    verticalsNow: 0,
  };
  detailDescription = {
    info: '',
    period: '',
    name: '',
  };
  detailPage = {
    common: {
      id: 0,
    },
    auditory: {
      city: null,
      gender: null,
      interest: null,
    },
    // sources: null,
    snStats: {
      snTabs: [],
      snData: {},
      activeTab: 0,
      activeTabNum: 0,
    },
    // creatives: null,
    // examples: null,
  };
  fieldErrors = {
    login: '',
    password: '',
  };
  isLoading = true;
  route = '';
  commonTip = {
    isShow: false,
    num: 0,
  }
  themeBlock = [
    {
      id: 0,
      name: '',
      image: '',
      color: "",
      coverage: 0,
      public_count: 0,
      post_count: 0,
      replies: 0,
      mean_coverage: 0,
      err: 0,
      newsbreak_count: 0

    }
  ];
  themeBlockSort = 'coverage';
  infoBlockSort = 'coverage';
  themeBlockAll = 0;
  infoBlockAll = 0;
  coverageInfoblockDiagram = []
  legendCategory = []
  detalPie = []
  postCreativ = []
  topComents = []
  sources = []
  creative = []
  coverageThemeblockDiagram = []
  userName = {}




  constructor() {
    makeAutoObservable(this);
    const token = localStorage.getItem('bfvertical | token');
    // const token = 'dmVydGljYWxfdXNlcjpnZmhqa20=';// localStorage.getItem('bfvertical | token');
    if (token) {
      this.authToken = token;
    }

    if (!token && window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
  }

  setToken(login, password) {
    try {
      const token = btoa(`${login}:${password}`);
      localStorage.setItem('bfvertical | token', token);
      this.authToken = token;
    } catch (error) {
      toast.error('Проверьте правильность введённых символов');
    }
  }

  SortThemeBlocks() {
    const tmp = sortData(this.themeBlock, this.themeBlockSort)
    this.themeBlock = tmp

    console.log('Sort', this.themeBlockSort)
  }

  SortInfoBlocks() {
    const tmp = sortData(this.cmVerticals, this.infoBlockSort)
    this.cmVerticals = tmp

    console.log('Sort', this.infoBlockSort)
  }


  cmChangeFilterSort() {
    const verticals = this.cmVerticalsAll;
    console.log('sort3', verticals)
    const filteredVerticals = this.cmVerticalFilter < 3 ? verticals.filter(v => v.type === this.cmVerticalFilter) : verticals;
    console.log('sort4', filteredVerticals)
    this.cmVerticals = filteredVerticals.sort((a, b) => (a[this.cmVerticalSort] < b[this.cmVerticalSort]) ? 1 : ((b[this.cmVerticalSort] < a[this.cmVerticalSort]) ? -1 : 0));

  }


  async getUserName() {
    try {
      const response = await axios.get(`${BASE_URL}/users/me`, {
        headers: {
          Authorization: `basic ${this.authToken}`
        }
      });
      console.log('user name:', response)


      const data = response.data;


      const user = {
        id: data.id,
        name: data.name,
        login: data.login,
      }


      this.userName = user;
    }
    catch (e) {
      toast.error('Неверное имя');

    }
  }



  async getDetailsBlock(id) {
    this.isLoading = true;
    if (!Number(id)) {
      window.location.href = '/404';
    }



    try {
      const response = await axios.get(`${BASE_URL}/newsbreaks/${id}?expand=description,sources,categories,topPosts,comments`, {
        headers: {
          Authorization: `basic ${this.authToken}`
        }
      });




      this.detailDescription = {
        name: response.data.name,
        period: `${dateFormat(response.data.date_start)} - ${dateFormat(response.data.date_finish)}`,
        info: response.data.description,
        themeblockId: response.data.themeblock_id,
      }


      // No Mock
      const data = response.data;
      const topCreativ = response.data.topPosts;
      const resServerDetal = response.data.history;
      const detalLegend = response.data.categories;
      const topComment = response.data.comments;
      const topSource = response.data.sources;




      // Mock

      // const topCreativ = mockDetal.topPosts;
      // const resServerDetal = mockDetal.history;
      // const detalLegend = mockDetal.categories;
      // const topSource = mockDetal.sources;

      console.log('!!!!!!Comment', topComment)


      const sourcearr = []
      this.sources = Object.values(topSource).forEach((dataSource) => {
        if (dataSource.og_title.trim() && dataSource.og_description.trim()) {
          sourcearr.push({
            url: dataSource.url,
            og_site_name: dataSource.og_site_name,
            og_title: dataSource.og_title,
            og_description: dataSource.og_description,
            og_image: dataSource.og_image

          })
        }

      })

      this.sources = sourcearr


      this.topComents = Object.values(topComment).map((dataComment) => {
        const comment = {
          id: dataComment.id,
          user_image: dataComment.user_image,
          user_name: dataComment.user_name,
          text: dataComment.text

        }

        return comment;

      })

      console.log("top Comment", this.topComents)



      // this.postCreativ = Object.values(topCreativ).map((dataPostCreative) => {
      //   const postCreativ = {
      //     channel: dataPostCreative.channel,
      //     text: dataPostCreative.text,
      //     url: dataPostCreative.url,
      //     image: dataPostCreative.image,
      //     coverage: dataPostCreative.coverage,
      //     replies: dataPostCreative.replies,
      //     err: dataPostCreative.err * 100,
      //     reactions: dataPostCreative.reactions

      //   }
      //   console.log("top5", postCreativ)
      //   return postCreativ;

      // })


      const postarr = []
      Object.values(topCreativ).forEach((dataPostCreative) => {

        console.log('aaaaaaaar', dataPostCreative)
        if (dataPostCreative.image || dataPostCreative.text) {
          postarr.push({
            channel: dataPostCreative.channel,
            text: dataPostCreative.text,
            url: dataPostCreative.url,
            image: dataPostCreative.image,
            coverage: dataPostCreative.coverage,
            replies: dataPostCreative.replies,
            err: dataPostCreative.err * 100,
            reactions: dataPostCreative.reactions

          })
        }
      })
      console.log('arrrrrr', postarr)
      // this.creative = postarr;
      // postarr = topCreativ
      this.postCreativ = postarr




      this.coverageInfoblockDiagram = Object.values(resServerDetal).map((dataDiagram) => {
        const hist = {
          date: dataDiagram.date,
          coverage: dataDiagram.coverage,
          post_count: dataDiagram.post_count
        }
        console.log("hist", hist)
        return hist;

      })



      let totalCov = 0

      detalLegend.forEach(item => {
        totalCov += item.coverage
      })

      this.legendCategory = Object.values(detalLegend).map((detalLegend) => {
        const catLeg = {
          id: detalLegend.id,
          name: detalLegend.name,
          title: detalLegend.name,
          color: detalLegend.color,
          coverage: detalLegend.coverage,
          coverageCount: detalLegend.coverage,
          coveragePercent: detalLegend.coverage / totalCov * 100,
        }

        return catLeg
      })

      const BlocLegend = []
      const pie = []

      this.legendCategory.forEach(detalLegend => {
        BlocLegend.push({
          id: detalLegend.id,
          name: detalLegend.name,

          color: detalLegend.color,
          coverage: detalLegend.coverage,
          coveragePercent: detalLegend.coverage / totalCov * 100,
        })
      })

      let legend = []
      let accAngle = 0;
      let prevLength = 0;
      let height =  3//legend.filter(v => v.coverageCount).length + 1;
      const minHeight = 2;
      let prevData = null;
      const realLegend = BlocLegend.filter(v => v.coveragePercent);
      const countSectors = realLegend.length || 1;
      // const delta = countSectors < 1 ? 1 : (height - minHeight) / countSectors;
      realLegend.forEach((block, num) => {
        accAngle += prevLength;
        // height = prevData === block.coverageCount ? height : height - delta;
        prevLength = Math.PI * 2 * (block.coverage / totalCov);
        prevData = block.coverageCount;
        pie.push({
          id: block.id,
          color: block.color,
          coverage: block.coverage,
          coveragePercent: block.coverage / totalCov * 100,
          num,
          label: { num: num, },
          startAngle: accAngle,
          lengthSector: accAngle + prevLength,
          endLengthSector: prevLength,
          height,
        });
      });

      this.detalPie = pie
      // this.legendCategory = BlocLegend;

      const infostat = {
        id: data.id,
        title: data.name,
        image: data.image,
        color: data.color,
        coverageCount: data.coverage,
        creativeCount: data.mean_coverage,
        publicCount: data.public_count,
        postCount: data.post_count,
        erCount: data.err * 100,
        shareCount: data.replies,
        reactions: data.reactions,

      }


      this.commonStats = { ...infostat };
      this.cmLegend = this.legendCategory;


      console.log('<<<<<<<DetailsBlock', response)
      this.isLoading = false


    }

    catch (e) {
      console.log('Get total stat error', e)
      this.isLoading = false


      if (e.response) {
        if (e.response.status === 404) {
          toast.error('Страница не найдена');
          return 404
        } else if ((e.response.status === 401)) {
          return 401;
        } else {
          return 500
        }
      }
      else {
        return 500
      }
    }
  }


  async getTotalStats() {
    this.isLoading = true;

    try {
      const response = await axios.get(`${BASE_URL}/themeblocks/total-stat`, {
        headers: {
          Authorization: `basic ${this.authToken}`
        }
      });

      this.commonStats = {
        coverageCount: response.data.coverage,
        creativeCount: response.data.mean_coverage,
        publicCount: response.data.public_count,
        postCount: response.data.post_count,
        erCount: response.data.err * 100,
        shareCount: response.data.replies,
        reactions: response.data.reactions
      }
      console.log('<<<<<<<TotlStat', response)
      this.isLoading = false;
      return ({ commonStats: this.commonStats })

    }
    catch (e) {

      toast.error('Неверный логин или пароль');
      // localStorage.removeItem('bfvertical | token');
      this.isLoading = false;

      return 401;


      // toast.error('Ошибка запроса данных');
      // return 500;


    }
  }

  async getCreativeDownload(title, id) {
    try {
      const response = await axios.get(`${BASE_URL}/newsbreaks/${id}/posts-export`, {
        headers: {
          Authorization: `basic ${this.authToken}`
        }
      });

      const contentType = response.headers['content-type']
      let a = document.createElement('a');
      const ext = contentType.match('text/plain') ? '.txt' : '';
      const file = new Blob([response.data], { type: contentType });
      const filename = `${title} - Топ 5 размещений${ext}`;

      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file, filename);
      }
      else {
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }

    } catch (e) {
      toast.error('Ошибка открытия файла на скачивание');
      toast.error('Ошибка запроса данных');
      return 500;
    }
  }

  async getInfoBlock(id) {
    this.isLoading = true;

    if (!Number(id)) {
      window.location.href = '/404';
    }

    try {
      const response = await axios.get(`${BASE_URL}/themeblocks/${id}?expand=history,newsbreaks,`, {
        headers: {
          Authorization: `basic ${this.authToken}`
        }
      });


      console.log('<<<<<<<InfoBlock', response)

      // Mock
      // const newsbreaks = mockInfoBlock;
      // No mock



      const data = response.data;
      const newsbreaks = response.data.newsbreaks;







      const tbstat = {
        id: data.id,
        title: data.name,
        image: data.image,
        color: data.color,
        coverageCount: data.coverage,
        creativeCount: data.mean_coverage,
        publicCount: data.public_count,
        postCount: data.post_count,
        erCount: data.err * 100,
        shareCount: data.replies,
        reactions: data.reaction,
      }

      this.cmVerticals = newsbreaks.map((vertical) => {
        let _history = [];
        if (vertical.history) {
          _history = Object.values(vertical.history).filter(v => v.coverage !== null);
          console.log("0ij0rijg", _history)
        }
        const info = {
          coverageCount: vertical.coverage,
          id: vertical.id,
          name: vertical.name,
          period: `${dateFormat(vertical.date_start)} - ${dateFormat(vertical.date_finish)}`,
          date_start: vertical.date_start,
          date_finish: vertical.date_finish,
          type: vertical.type_id,
          color: vertical.color,
          coverage: vertical.coverage,
          publicCount: vertical.public_count,
          postCount: vertical.post_count,
          commentCount: vertical.replies,
          mean_coverage: vertical.mean_coverage,
          erCount: vertical.err * 100,
          progress: vertical.progress,
          reactions: vertical.reactions,

          history: _history,


        }
        console.log("55555", info)
        return info
      })

      const infoBlockLegend = []
      let totalCoverage = 0;
      this.cmVerticals.forEach(item => {
        totalCoverage += item.coverage
      })

      let url = 'newsbreak'

      this.cmVerticals.forEach(infoblock => {
        infoBlockLegend.push({
          id: infoblock.id,
          coverageCount: infoblock.coverage,
          coveragePercent: infoblock.coverage / totalCoverage * 100,
          color: infoblock.color,
          title: infoblock.name,
          type: 1,
          url
        })
        console.log('<<<<<<<InfoBlockPieLegend', infoBlockLegend)

      })
      const pie = []
      let legend = []
      let accAngle = 0;
      let prevLength = 0;
      let height = 5 //legend.filter(v => v.coverageCount).length + 1;
      const minHeight = 2;
      let prevData = null;
      const realLegend = infoBlockLegend.filter(v => v.coveragePercent);
      const countSectors = realLegend.length || 1;
      // const delta = countSectors < 1 ? 1 : (height - minHeight) ;
      realLegend.forEach((newsbreaks, num) => {
        accAngle += prevLength;
        // height = prevData === newsbreaks.coverageCount ? height : height - delta;
        prevLength = Math.PI * 2 * (newsbreaks.coverageCount / totalCoverage);
        prevData = newsbreaks.coverageCount;
        pie.push({
          id: newsbreaks.id,
          color: newsbreaks.color,
          coverage: newsbreaks.coverageCount,
          coveragePercent: newsbreaks.coverageCount / totalCoverage * 100,
          num,
          label: { num: num, },
          startAngle: accAngle,
          lengthSector: accAngle + prevLength,
          endLengthSector: prevLength,
          height,
          url
        });
      });
      console.log('<<<<<<<InfoBlockPie', pie)
      this.commonPie = pie
      this.cmLegend = infoBlockLegend;
      this.infoBlockAll = infoBlockLegend.length;
      this.commonStats = { ...tbstat };
      this.cmVerticalsAll = this.cmVerticals.slice()
      this.isLoading = false;

      return 200

    }


    catch (e) {
      console.log('Get total stat error', e)
      this.isLoading = false
      if (e.response.status === 404) {
        toast.error('Страница не найдена');
        return 404
      } else {
        return 401
      }

    }
  }

  // finish
  async getThemeBlocksData() {
    this.isLoading = true;


    try {
      const response = await axios.get(`${BASE_URL}/themeblocks`, {
        headers: {
          Authorization: `basic ${this.authToken}`
        }
      });

      // Mock
      // const data = themeblocks;
      // No mock
      const data = response.data;


      const sort = data.map(item => {
        const tmp = {
          id: item.id,
          name: item.name,
          image: item.image,
          color: item.color,
          coverage: item.coverage,
          public_count: item.public_count,
          post_count: item.post_count,
          replies: item.replies,
          mean_coverage: item.mean_coverage,
          err: item.err * 100,
          newsbreak_count: item.newsbreak_count,
          reactions: item.reactions
        }
        return tmp
      })

      this.themeBlock = sortData(sort, this.themeBlockSort)

      console.log('uuuuuuuuu', this.themeBlock)

      const themeBlocLegend = []
      const pie = []
      let totalCoverage = 0;
      this.themeBlock.forEach(item => {
        totalCoverage += item.coverage
      })

      let url = 'info'
      this.themeBlock.forEach(themeblock => {
        themeBlocLegend.push({
          id: themeblock.id,
          title: themeblock.name,
          coverageCount: themeblock.coverage,
          coveragePercent: themeblock.coverage / totalCoverage * 100,
          color: themeblock.color,
          url,
          type: 2
        })
      })
      console.log('ThemeBlockLegend', themeBlocLegend.length)


      let legend = []
      let accAngle = 0;
      let prevLength = 0;
      let height =  5//legend.filter(v => v.coverageCount).length + 1;
      const minHeight = 2;
      let prevData = null;
      const realLegend = themeBlocLegend.filter(v => v.coveragePercent > 0.5);
      const countSectors = realLegend.length || 1;
      // const delta = countSectors < 1 ? 1 : (height - minHeight); //   / countSectors
      realLegend.forEach((themeblock, num) => {
        accAngle += prevLength;
        // height = prevData === themeblock.coverageCount ? height : height - delta;
        prevLength = Math.PI * 2 * (themeblock.coverageCount / totalCoverage);
        prevData = themeblock.coverageCount;
        pie.push({
          id: themeblock.id,
          color: themeblock.color,
          coverage: themeblock.coverageCount,
          coveragePercent: themeblock.coverageCount / totalCoverage * 100,
          num,
          label: { num: num, },
          startAngle: accAngle,
          lengthSector: accAngle + prevLength,
          endLengthSector: prevLength,
          height,
          url
        });
      });

      this.commonPie = pie
      this.cmLegend = themeBlocLegend;
      this.themeBlockAll = themeBlocLegend.length;

      console.log('pie', pie)

      console.log('Legend',)

      console.log('>>>>>>>ThemBlocks', response)

      const resServer = response.data;
      // const resServer = mockVerticals;// response.data;
      this.fieldErrors = {
        login: '',
        password: '',
      };

      let _legend = [];
      const _pie = [];
      let _commonStat = {
        coverageCount: 0,
        publicCount: 0,
        postCount: 0,
        creativeCount: 0,
        likeCount: 0,
        shareCount: 0,
        commentCount: 0,
        erCount: 0,
      };
      const verticals = [];

      resServer.forEach(vertical => {
        let _history = [];
        if (vertical.stat && vertical.stat.history) {
          _history = Object.values(vertical.stat.history).filter(v => v.coverage !== null);
        }
        verticals.push({
          id: vertical.id,
          title: vertical.name,
          coverageCount: vertical.stat ? +vertical.stat.coverage || 0 : null,
          publicCount: vertical.stat ? +vertical.stat.public_count || 0 : null,
          postCount: vertical.stat ? +vertical.stat.post_count || 0 : null,
          creativeCount: vertical.stat ? +vertical.stat.creative_count || 0 : null,
          likeCount: vertical.stat ? +vertical.stat.like_count || null : null,
          shareCount: vertical.stat ? +vertical.stat.repost_count || null : null,
          commentCount: vertical.stat ? +vertical.stat.comment_count || null : null,
          erCount: vertical.stat ? +vertical.stat.er * 100 || null : null,
          type: +vertical.type_id,
          progress: vertical.progress === null ? null : +vertical.progress,
          coverageHistory: _history,
          period: `${dateFormat(vertical.date_start)} - ${dateFormat(vertical.date_finish)}`,
          color: vertical.color,
        });
      });

      this.cmVerticalsAll = verticals;

      const _filteredVerticals = this.cmVerticalFilter < 3 ? verticals.filter(v => v.type === this.cmVerticalFilter) : verticals;

      this.cmVerticals = _filteredVerticals.sort((a, b) => (a[this.cmVerticalSort] < b[this.cmVerticalSort]) ? 1 : ((b[this.cmVerticalSort] < a[this.cmVerticalSort]) ? -1 : 0));

      verticals.forEach(vertical => {
        _commonStat = {
          coverageCount: _commonStat.coverageCount + vertical.coverageCount,
          publicCount: _commonStat.publicCount + vertical.publicCount,
          postCount: _commonStat.postCount + vertical.postCount,
          creativeCount: _commonStat.creativeCount + vertical.creativeCount,
          likeCount: _commonStat.likeCount + (vertical.likeCount || 0),
          shareCount: _commonStat.shareCount + (vertical.shareCount || 0),
          commentCount: _commonStat.commentCount + (vertical.commentCount || 0),
          erCount: 0,
        }
      });

      _commonStat.erCount = +_commonStat.coverageCount ? ((_commonStat.likeCount || 0) + (_commonStat.shareCount || 0) + (+_commonStat.commentCount || 0)) / _commonStat.coverageCount * 100 : 0;
      _commonStat.verticalsNow = verticals.filter(v => v.type_id === 2).length;

      _commonStat.verticals = verticals.length;
      _commonStat.verticalsNow = verticals.filter(v => v.type === 2).length;
      this.commonStats = _commonStat;

      verticals.forEach(vertical => {
        _legend.push({
          id: vertical.id,
          title: vertical.title,
          coverageCount: vertical.coverageCount,
          coveragePercent: vertical.coverageCount / _commonStat.coverageCount * 100,
          color: vertical.color,
        });
      });
      _legend = _legend.sort((a, b) => (a.coverageCount < b.coverageCount) ? 1 : ((b.coverageCount < a.coverageCount) ? -1 : 0));
      // this.cmLegend = _legend;


      // let accAngle = 0;
      // let prevLength = 0;
      // let height = 7.5//_legend.filter(v => v.coverageCount).length + 1;
      // const minHeight = 1;
      // let prevData = null;
      // const _realLegend = _legend.filter(v => v.coveragePercent);
      // const countSectors = _realLegend.length || 1;
      // const delta = countSectors < 2 ? 2 : (height - minHeight) / countSectors;
      // _realLegend.forEach((vertical, num) => {
      //   accAngle += prevLength;
      //   height = prevData === vertical.coverageCount ? height : height - delta;
      //   prevLength = Math.PI * 2 * (vertical.coverageCount / _commonStat.coverageCount);
      //   prevData = vertical.coverageCount;
      //   _pie.push({
      //     id: vertical.id,
      //     color: vertical.color,
      //     coverage: vertical.coverageCount,
      //     coveragePercent: vertical.coverageCount / _commonStat.coverageCount * 100,
      //     num,
      //     label: { num: num, },
      //     startAngle: accAngle,
      //     lengthSector: accAngle + prevLength,
      //     endLengthSector: prevLength,
      //     height,
      //   });
      // });
      // this.commonPie = _pie;
      // this.isLoading = false;

      // return this;
      // this.isLoading = false


    } catch (error) {
      this.isLoading = false;
      let status = 200;


      if (error && error.response && error.response.status) {
        status = error.response.status
      }

      if (status === 401) {
        toast.error('Неверный логин или пароль');
        localStorage.removeItem('bfvertical | token');
        return 401;
      } else {
        toast.error('Ошибка запроса данных');
        return 500;
      }
    }
  }

  async getVerticalById(id) {
    this.isLoading = true;
    this.detailPage = {
      common: {
        id: 0,
      },
      auditory: {
        city: null,
        gender: null,
        interest: null,
      },
      sources: null,
      snStats: {
        snTabs: [],
        snData: {},
        activeTab: 0,
        activeTabNum: 0,
      },
      creatives: null,
      examples: null,
    }

    try {
      const response = await axios.get(`${BASE_URL}/verticals/${id}?expand=description,sources,topCreatives,postExamples,audienceCityStructure,audienceInterestStructure,audienceSexAgeStructure,socialNetworksStat`, {
        headers: {
          Authorization: `basic ${this.authToken}`
        }
      })

      // console.log('>>>> response', response)

      const resServer = response.data;
      // const resServer = mock13Vertical; // response.data;

      const _pie = [];

      let _commonStat = {
        coverageCount: resServer.stat.coverage,
        publicCount: resServer.stat.public_count,
        postCount: resServer.stat.post_count,
        creativeCount: resServer.stat.creative_count,
        likeCount: resServer.stat.like_count,
        shareCount: resServer.stat.repost_count,
        commentCount: resServer.stat.comment_count,
        erCount: resServer.stat.er * 100,
      };

      this.commonStats = _commonStat;
      this.detailDescription = {
        name: resServer.name,
        info: resServer.description,
        period: `${dateFormat(resServer.date_start)} - ${dateFormat(resServer.date_finish)}`
      }

      const examples = [];

      if (resServer.postExamples && resServer.postExamples.length) {
        SocialNetworks.forEach(sn => {
          const _arrExamples = resServer.postExamples.filter(v => v.social_network_id === sn.id).map(v => ({ sn: v.social_network_id, url: v.url }));
          if (_arrExamples.length) {
            examples.push({
              title: sn.title,
              shortName: sn.shortName,
              examples: _arrExamples,
            });
          }
        })
      }

      const _gender = []
      const _city = []
      const _interest = []
      let auditory = {
        city: null,
        gender: null,
        interest: null,
      };

      let maxValue = 0;

      if (resServer.audienceCityStructure && resServer.audienceCityStructure.length) {
        maxValue = 0;
        const tmpArr = sortData(resServer.audienceCityStructure, 'param_value').slice(0, 5);
        tmpArr.forEach(v => maxValue += v.param_value);

        if (maxValue) {
          tmpArr.forEach(v => {
            _city.push({
              title: v.param_name,
              value: v.param_value,
              percent: v.param_value / maxValue * 100,
              decimal: v.param_value / maxValue,
            })
          });
        }

        if (_city.length) {
          auditory.city = _city;
        }
      }

      if (resServer.audienceInterestStructure && resServer.audienceInterestStructure.length) {
        maxValue = 0;
        const tmpArr = sortData(resServer.audienceInterestStructure, 'param_value').slice(0, 5);
        tmpArr.forEach(v => maxValue += v.param_value);

        if (maxValue) {
          tmpArr.forEach(v => {
            _interest.push({
              title: v.param_name,
              value: v.param_value,
              percent: v.param_value / maxValue * 100,
              decimal: v.param_value / maxValue
            })
          });
        }

        if (_interest.length) {
          auditory.interest = _interest;
        }
      }

      if (resServer.audienceSexAgeStructure && resServer.audienceSexAgeStructure.length) {
        maxValue = 0;
        const tmpArr = resServer.audienceSexAgeStructure;
        tmpArr.forEach(v => maxValue += v.param_value);

        GenderAge.forEach(age => {
          let arrGender = [];
          if (age.min) {
            if (age.max) {
              arrGender = tmpArr.filter(v => v.min_age === age.min && v.max_age === age.max);
            } else {
              arrGender = tmpArr.filter(v => v.min_age === age.min);
            }
          } else {
            arrGender = tmpArr.filter(v => v.max_age === age.max);
          }

          if (arrGender.length) {
            let t = {
              label: age.label,
            };
            arrGender.forEach(g => {
              t[Gender[g.sex_id].name] = g.param_value / maxValue * 100;
            })
            _gender.push(t);
          }
        })

        if (_gender.length) {
          auditory.gender = _gender;
        }
      }

      // const snTabs = [];
      // const snData = {};
      // let snStats = {
      //   snTabs: [],
      //   snData: {},
      //   activeTab: 0,
      //   activeTabNum: 0,
      // };
      // SocialNetworks.forEach(sn => {
      //   if (resServer.socialNetworksStat[sn.id] && resServer.socialNetworksStat[sn.id].coverage !== null) {
      //     const data = resServer.socialNetworksStat[sn.id];
      //     snTabs.push(sn);
      //     const snDiagram = [];
      //     data.history.forEach(diagramData => {
      //       if (diagramData.coverage !== null || diagramData.post_count !== null) {
      //         snDiagram.push({
      //           coverage: diagramData.coverage || 0,
      //           date: diagramData.date,
      //           postCount: diagramData.post_count || 0,
      //         })
      //       }
      //     });
      //     snData[sn.id] = {
      //       id: sn.id,
      //       commentCount: data.comment_count,
      //       coverageCount: data.coverage || 0,
      //       creativeCount: data.creative_count || 0,
      //       erCount: data.er === null ? null : data.er * 100,
      //       // snDiagram,
      //       likeCount: data.like_count,
      //       postCount: data.post_count || 0,
      //       publicCount: data.public_count || 0,
      //       shareCount: data.repost_count,
      //     }
      //   }
      // });
      // if (snTabs.length) {
      //   snStats = {
      //     snTabs: snTabs,
      //     snData: snData,
      //     activeTab: snTabs[0].id,
      //     activeTabNum: 0,
      //   };

      //   let accAngle = 0;
      //   let prevLength = 0;
      //   let height = 4;
      //   let prevData = null;
      //   let tmpPie = [];
      //   snTabs.forEach(sn => {
      //     tmpPie.push({
      //       id: sn.id,
      //       color: sn.color,
      //       coverage: snData[sn.id].coverageCount,
      //       coveragePercent: snData[sn.id].coverageCount / _commonStat.coverageCount * 100,
      //       isSN: true,
      //       num: sn.shortName,
      //       label: { num: sn.shortName, },
      //     });
      //   })

      //   tmpPie = sortData(tmpPie, 'coverage');
      //   const delta = tmpPie.length < 2 ? 2 : height / tmpPie.length;
      //   prevData = tmpPie[0].coverage;
      //   tmpPie.forEach(sector => {
      //     accAngle += prevLength;
      //     height = prevData === sector.coverage ? height : height - delta;
      //     prevLength = Math.PI * 2 * (sector.coverage / _commonStat.coverageCount);
      //     prevData = sector.coverage;
      //     _pie.push({
      //       ...sector,
      //       startAngle: accAngle,
      //       lengthSector: accAngle + prevLength,
      //       endLengthSector: prevLength,
      //       height,
      //     });
      //   })

      // snTabs.forEach(sn => {
      //   accAngle += prevLength;
      //   height = prevData === snData[sn.id].coverageCount ? height : height - 0.3;
      //   prevLength = Math.PI * 2 * snData[sn.id].coverageCount / _commonStat.coverageCount;
      //   _pie.push({
      //     id: sn.id,
      //     color: sn.color,
      //     coverage: snData[sn.id].coverageCount,
      //     coveragePercent: snData[sn.id].coverageCount / _commonStat.coverageCount * 100,
      //     isSN: true,
      //     num: sn.shortName,
      //     label: { num: sn.shortName, },
      //     startAngle: accAngle,
      //     lengthSector: accAngle + prevLength,
      //     height,
      //   });
      // })
      // }

      const sources = resServer.sources ? resServer.sources.map(v => ({
        url: v.url,
        site: v.og_site_name,
        title: v.og_title,
        description: v.og_description,
        image: v.og_image,
      })) : [];

      const creatives = [];

      if (resServer.topCreatives && resServer.topCreatives.length) {
        SocialNetworks.forEach(sn => {
          const _creatives = resServer.topCreatives.filter(v => v.social_network_id === sn.id);
          if (_creatives.length) {
            creatives.push({
              ...sn,
              creatives: _creatives.slice(0, 5).map(_cr => ({
                commentCount: _cr.comment_count,
                coverageCount: _cr.coverage,
                creativeCount: _cr.creative_count,
                image: _cr.image,
                likeCount: _cr.like_count,
                postCount: _cr.post_count,
                publicCount: _cr.public_count,
                shareCount: _cr.repost_count,
                er: (_cr.like_count + _cr.comment_count + _cr.repost_count) / _cr.coverage * 100,
              }))
            });
          }
        });
      }

      this.commonPie = _pie;

      this.detailPage = {
        examples,
        auditory,
        // snStats,
        creatives,
        sources,
        common: {
          id
        }
      }

      // console.log('>>> ', this.detailPage);
      this.isLoading = false;

      return this;

    } catch (error) {
      this.isLoading = false;
      let status = 200;

      if (error && error.response && error.response.status) {
        status = error.response.status
      }

      if (status === 401) {
        toast.error('Неверный логин или пароль');
        localStorage.removeItem('bfvertical | token');
        return 401;
      } else if (status === 404) {
        toast.error('Данные по вертикали не найдены');
        return 404;
      } else {
        toast.error('Ошибка запроса данных');
        return 500;
      }
    }
  }

  // cmChangeFilterSort() {
  //   const verticals = this.cmVerticalsAll;

  //   const _filteredVerticals = this.cmVerticalFilter < 3 ? verticals.filter(v => v.type === this.cmVerticalFilter) : verticals;

  //   this.cmVerticals = _filteredVerticals.sort((a, b) => (a[this.cmVerticalSort] < b[this.cmVerticalSort]) ? 1 : ((b[this.cmVerticalSort] < a[this.cmVerticalSort]) ? -1 : 0));
  // }

  handleCommonTip(isShow, num) {
    this.commonTip = {
      isShow,
      num
    }
  }

}

const appStore = new AppStore();

export default appStore;