import React from "react";

import { ReactComponent as LikesSVG } from '../../svg/like3.svg'
import { ReactComponent as CommentsSVG } from '../../svg/com2.svg'
import { ReactComponent as ErrSVG } from '../../svg/err_tb.svg'
import { ReactComponent as ERSVG } from '../../svg/err.svg'
import { ReactComponent as ERRPostSVG } from '../../svg/err_stroke.svg';
import { ReactComponent as ERRInfoSVG } from '../../svg/errinfo.svg';
import { prettyNumber } from "../../utils/Formatter";

const Icon = ({ icon, className }) => {
  switch (icon) {
    case 'likes':
      return <LikesSVG className={className} />;
    case 'comments':
      return <CommentsSVG className={className} />;
    case 'err':
      return <ErrSVG className={className} />;
    case 'er':
      return <ERSVG className={className} />;
    case 'errpost':
      return <ERRPostSVG className={className} />;
      case 'errinfo':
      return <ERRInfoSVG className={className} />;

    default:
      return <></>;
  }
}

const ParameterIcon = ({
  param
}) => {

  return (
    <>
      <div className="parameter-icon">
        <Icon className={`parameter-icon__icon-${param.size}`} icon={param.icon} />
        <div className={`parameter-icon__value parameter-icon__value-${param.size}`}>{prettyNumber(param.value)}{(param.icon === 'er' || param.icon === 'err' || param.icon === 'errinfo' ) ? '%' : ''}</div>
      </div>
      {/* <div className="parameter-posticon">
      <Icon className={`parameter-posticon__icon-${param.size}`} icon={param.icon} />
      <div className={`parameter-posticon__value parameter-posticon__value-${param.size}`}>{prettyNumber(param.value)}{param.icon ===  'errpost' ? '%' : ''}</div> */}
      {/* </div> */}

    </>
  );
}

export default ParameterIcon;