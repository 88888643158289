import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Tooltip,
} from "recharts";
import { prettyNumber } from "../../utils/Formatter";
import useWindowDimensions from "../../utils/Hooks";

const CustomTooltip = ({ active, payload, data, label }) => {
  if (active && payload && payload.length && label) {
    const tt = data.filter(v => v.label === label);
    return (
      <div className="tooltip-sn-coverage">
        <div className="tooltip-sn-coverage__date">{label}</div>
        <div className="tooltip-sn-coverage__coverage"><span>Женщин</span>{prettyNumber(tt[0].genderW || 0)}%</div>
        <div className="tooltip-sn-coverage__posts"><span>Мужчин</span>{prettyNumber(tt[0].genderM || 0)}%</div>
      </div>
    );
  }

  return null;
}

function formatYAxis(tickItem) {
  return prettyNumber(tickItem) + '%';
}

const AuditoryBar = ({
  dataDiagram
}) => {
  // return null;
  const { isPhone } = useWindowDimensions();

  return (
    <ResponsiveContainer width="100%" height={250}>
      <ComposedChart
        data={dataDiagram}
        barGap={0}
        // barCategoryGap={0}
        margin={{left: 0, right: 0, top: 0}}
      >
        <CartesianGrid strokeDasharray="3 3"  />
        <XAxis dataKey="label" dy={5} height={25} tick={{ fill: '#FFFFFF', fontSize: isPhone ? '10px' : '14px' }} />
        <YAxis yAxisId="right" tickFormatter={formatYAxis} type="number" orientation="left" tick={{ fill: '#FFFFFF', fontSize: isPhone ? '10px' : '14px' }} width={isPhone ? 30 : 50}/>
        <Tooltip content={<CustomTooltip data={dataDiagram}/>} />
        <Bar cursor="pointer" name="Женщины" yAxisId="right" dataKey="genderW" fill="#DD4242" barSize={22} radius={[8, 8, 0, 0]}>
        </Bar>
        <Bar cursor="pointer" name="Мужчины" yAxisId="right" dataKey="genderM" fill="#5282FF" barSize={22} radius={[8, 8, 0, 0]}>
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default AuditoryBar;