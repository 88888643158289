import { observer } from "mobx-react-lite";
import React from "react";
import appStore from "../../Store/MainStore";
import useWindowDimensions from "../../utils/Hooks";
import InfoBlock from "../InfoBlock/InfoBlock";
import CreativeComment from "./CreativeComment";
import SliderCreatives from "./SliderCreatives";

const TopCreativesBlock = () => {

  const { isPhone, isBigPhoneNew } = useWindowDimensions();


  return <>
    <div >

      {appStore.postCreativ && appStore.postCreativ.length ? <div className={`${appStore.postCreativ.length > 2 ? 'container-all ' : 'container-all '}`}>
        <InfoBlock  btndl={true} line={isBigPhoneNew ? true : false} title={`${isPhone ? 'ТОП-5 размещений' : 'ТОП-5 размещений'}`}>
        </InfoBlock>
        <SliderCreatives />
      </div> : null}
      {appStore.topComents.length > 1 ? <div className="container-all">
        <div className="creativecomment__title"> Актуальные комментарии</div>
        {isBigPhoneNew ? <div className="infoblock__line"></div> : null}
        <div className="creativecomment__list">
          <CreativeComment ></CreativeComment>
        </div>
      </div> : null}


    </div>
  </>;



};

export default observer(TopCreativesBlock);