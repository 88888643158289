
import { ReactComponent as ArrowSVG } from '../../svg/arrow_right.svg'
import { ReactComponent as ArrowMobSVG } from '../../svg/arr_mob.svg'
import { ReactComponent as ChatSVG } from '../../svg/chat_tb.svg'
import { ReactComponent as LikeSVG } from '../../svg/like_tb.svg'
import { ReactComponent as ErrSVG } from '../../svg/err_tb.svg'
import appStore from '../../Store/MainStore'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { pluralWords } from "../../utils/Formatter";
// import React from 'react'
import ParameterIcon from "../Parameter/ParameterIcon";
import { prettyNumber, prettyNumberPostfix } from "../../utils/Formatter";
import NoData from '../Card/NoData';
import { themeblocks } from '../../Store/mock';
import ReactTooltip from 'react-tooltip'
import { toast } from 'react-toastify';
import NoDataMob from '../Card/NoDataMob'
import CardBorderedTb from '../Card/CardBorderedTb'



const ThemeBlock = ({

}) => {

    const navigate = useNavigate();

    return (
        <>


            <div className='themeblock__all'>


                {
                    appStore.themeBlock.map((themeBlocks, index) => (

                        <div className='border' key={themeBlocks.id}>
                            < div className="themeblock__body"  onClick={() => {
                                if (themeBlocks.newsbreak_count > 0) {
                                    navigate(`/info/${themeBlocks.id}`)
                                } else {

                                }
                            }} >


                                <div className="themeblock__item" >
                                    {themeBlocks.coverage > 0 ? <div className='themeblock__footer-mob'>
                                        <div className="themeblock__data-item">
                                            <div className="themeblock__data-title">
                                                <ErrSVG className="themeblock__chat"></ErrSVG>
                                            </div>

                                            <div className="themeblock__data-num">
                                                {themeBlocks.err}%
                                                {/* {themeBlocks.err !== null ? <ParameterIcon key={`card-vertical-er`} param={{ value: themeBlocks.err, icon: 'err', size: 'big' }} /> : null} */}
                                            </div>
                                        </div>
                                        <div className="themeblock__data-item">
                                            <div className="themeblock__data-title">
                                                <ChatSVG className="themeblock__chat"></ChatSVG>
                                            </div>
                                            <div className="themeblock__data-num">
                                                {prettyNumber(themeBlocks.replies)}

                                            </div>
                                        </div>
                                        <div className="themeblock__data-item">
                                            <div className="themeblock__data-title">
                                                <LikeSVG className="themeblock__like"></LikeSVG>
                                            </div>
                                            <div className="themeblock__data-num">
                                                {prettyNumber(themeBlocks.reactions)}

                                            </div>
                                        </div>
                                    </div> : null}
                                    <div className='themeblock__item-mob'>
                                        <img className="themeblock__img" src={themeBlocks.image}></img>
                                        {themeBlocks.coverage > 0 ? <div className='themeblock__data-mob'>
                                            <div className='themeblock__data-clm'>
                                                <div className="themeblock__data-item">
                                                    <div className="themeblock__data-title">
                                                        Охват
                                                    </div>
                                                    <div className="themeblock__data-num" data-tip={themeBlocks.coverage > 100000000 ? prettyNumber(themeBlocks.coverage) : ''}>
                                                        {prettyNumber(themeBlocks.coverage)}

                                                    </div>
                                                </div>
                                                <div className="themeblock__data-item">
                                                    <div className="themeblock__data-title">
                                                        {pluralWords(themeBlocks.public_count, ["Канал", "Канала", "Каналов"])}
                                                    </div>
                                                    <div className="themeblock__data-num">
                                                        {prettyNumber(themeBlocks.public_count)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='themeblock__data-clm'>
                                                <div className="themeblock__data-item">
                                                    <div className="themeblock__data-title">
                                                        Размещений
                                                    </div>
                                                    <div className="themeblock__data-num">
                                                        {prettyNumber(themeBlocks.post_count)}

                                                    </div>
                                                </div>
                                                <div className="themeblock__data-item">
                                                    <div className="themeblock__data-title">
                                                        Ср. охват
                                                    </div>
                                                    <div className="themeblock__data-num">
                                                        {prettyNumberPostfix(themeBlocks.mean_coverage, 0, 100000000)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : <div className='themeblock__no-data-mob'><NoDataMob></NoDataMob> </div>}

                                    </div>







                                    <div className="themeblock__info" >
                                        <div className="themeblock__header" >
                                            <div className="themeblock__header-title" data-tip={themeBlocks.name} >
                                                {themeBlocks.name}
                                            </div>
                                            {themeBlocks.newsbreak_count > 0 ? <a className="themeblock__header-href"

                                            > {themeBlocks.newsbreak_count} {pluralWords(themeBlocks.newsbreak_count, ['инфоповод', 'инфоповода', 'инфоповодов'])}

                                                <ArrowSVG className="themeblock__arrow"></ArrowSVG>
                                                <ArrowMobSVG className="themeblock__arrow-mob"></ArrowMobSVG>
                                            </a> : null}
                                        </div>
                                        {themeBlocks.coverage > 0 ? <div className="themeblock__data">
                                            <div className="themeblock__data-item">
                                                <div className="themeblock__data-title">
                                                    Текущий охват
                                                </div>
                                                <div className="themeblock__data-num" data-tip={themeBlocks.coverage > 100000000 ? prettyNumber(themeBlocks.coverage) : ''}>
                                                    {prettyNumberPostfix(themeBlocks.coverage, 0, 100000000)}

                                                </div>
                                            </div>
                                            <div className="themeblock__data-item">
                                                <div className="themeblock__data-title">
                                                    {pluralWords(themeBlocks.public_count, ["Канал", "Канала", "Каналов"])}
                                                </div>
                                                <div className="themeblock__data-num">
                                                    {prettyNumber(themeBlocks.public_count)}

                                                </div>
                                            </div>
                                            <div className="themeblock__data-item">
                                                <div className="themeblock__data-title">
                                                    Размещений
                                                </div>
                                                <div className="themeblock__data-num">
                                                    {prettyNumber(themeBlocks.post_count)}

                                                </div>
                                            </div>
                                            <div className="themeblock__data-item">
                                                <div className="themeblock__data-title">
                                                    Ср. охват поста
                                                </div>
                                                <div className="themeblock__data-num">
                                                    {prettyNumber(themeBlocks.mean_coverage)}

                                                </div>
                                            </div>
                                            <div className="themeblock__data-item">

                                                <div className="themeblock__data-num">

                                                    {themeBlocks.err !== null ? <ParameterIcon key={`card-vertical-er`} param={{ value: themeBlocks.err, icon: 'err', size: 'medium' }} /> : null}
                                                </div>
                                            </div>
                                            <div className="themeblock__data-item">
                                                <div className="themeblock__data-title">
                                                    <ChatSVG className="themeblock__chat"></ChatSVG>
                                                </div>
                                                <div className="themeblock__data-num">
                                                    {prettyNumber(themeBlocks.replies)}

                                                </div>
                                            </div>
                                            <div className="themeblock__data-item">
                                                <div className="themeblock__data-title">
                                                    <LikeSVG className="themeblock__like"></LikeSVG>
                                                </div>
                                                <div className="themeblock__data-num">
                                                    {prettyNumber(themeBlocks.reactions)}

                                                </div>
                                            </div>
                                        </div> : <div className='themeblock__no-data'><NoData></NoData> </div>}

                                    </div>

                                </div>

                                <ReactTooltip></ReactTooltip>
                            </div>
                        </div>
                    ))
                }

            </div>
        </>
    )
}


export default observer(ThemeBlock);


