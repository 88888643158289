import React from "react";
import { ReactComponent as WarningSVG } from '../../svg/warning.svg'

const NoData = () => {
  return <div className="card-vertical__no-data">
    <div className="card-vertical__no-data-title"><WarningSVG />Данных пока нет</div>
    {/* <div className="card-vertical__no-data-info">Это новая вертикаль. Данные не успели сформироваться</div> */}
  </div>;
}

export default NoData;