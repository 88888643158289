import React from "react";
import { observer } from 'mobx-react-lite';
import ReactTooltip from 'react-tooltip';

import appStore from "../../Store/MainStore";
import { prettyNumber } from "../../utils/Formatter";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Legend = () => {
  const navigate = useNavigate();

  return (<>

    {
      appStore.cmLegend.map((item, index) => (
        <div onClick={() => {
          if (item.coverageCount) {
            navigate(`/${item.url}/${item.id}`)

          } else {
            toast('Данные по вертикали в процессе сбора')
          }
        }} key={`legend-${item.id}`} className="top-content__legends-item">
          <div className="top-content__legends-num" style={{ backgroundColor: item.color }}>
            <div className="top-content__legends-inner">{index+1}</div>
          </div>
          <div className="top-content__legends-info">
            <div className="top-content__legends-name" data-tip={item.title}>{item.title}</div>
            <div className="top-content__legends-value">Охват: {prettyNumber(item.coverageCount)} ({prettyNumber(item.coveragePercent)}%)</div>
          </div>
          <ReactTooltip />
        </div>
      ))
    }
  </>); 
}

export default observer(Legend);