import React, { useEffect, useRef, useState } from "react";
import { prettyNumber } from "../../utils/Formatter";

const ProgressBar = ({
  value,
  isHideValue = false,
  maxValue = 173,
  className = '',
}) => {
  const ref = useRef(null);
  const [progressWidth, setProgressWidth] = useState(maxValue);
  useEffect(() => {
    if (ref && ref.current) {
      setProgressWidth(ref.current.clientWidth);
    }
  }, [ref])
  return (
    <div ref={ref} className={`progress ${className}`}>
      <div className={`progress__fill ${value > 0.95 ? 'progress__fill_full' : ''}`} style={{ width: value > 1 ? progressWidth : value < 0.04 ? 0.04 * progressWidth : progressWidth * value }}></div>
      { isHideValue ? null : <div className="progress__value">{prettyNumber(value * 100)}%</div> }
    </div>
  );
}

export default ProgressBar;