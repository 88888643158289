import { observer } from "mobx-react-lite";
import React, {useEffect} from "react";
import { useNavigate, useParams } from "react-router";
import appStore from "../../Store/MainStore";
import { themeblocks } from "../../Store/mock";
import { ReactComponent as ArrowSVG } from '../../svg/arrow_back.svg'

const DescriptionBlock = ({ isPhone, isBigPhone }) => {
  const navigator = useNavigate();



  // console.log('hhhhh', themeblockId)

  if (isPhone) {
    return (

      <div className="description-block">
        <div className="description-block__top">
          <div onClick={() => navigator(`/info/${appStore.detailDescription.themeblockId}`)} className="description-block__comeback"><ArrowSVG className="description-block__arrow"></ArrowSVG>Назад</div>
          <div className="description-block__top-text">
            <div className="description-block__title">{appStore.detailDescription.name}</div>
            <div className="description-block__period">{appStore.detailDescription.period}</div>
          </div>
        </div>
        <div  className="description-block__info">{appStore.detailDescription.info}</div>
      </div>
    )
  }
  return (
    <div className="description-block">
      <div className="description-block__top">
        <div onClick={() => navigator(`/info/${appStore.detailDescription.themeblockId}`)} className="description-block__comeback"><ArrowSVG className="description-block__arrow"></ArrowSVG>Назад</div>
        <div className="description-block__title">{appStore.detailDescription.name}</div>
        <div className="description-block__period">{appStore.detailDescription.period}</div>
      </div>
      <div  className="description-block__info">{appStore.detailDescription.info}</div>
    </div>
  )
}

export default observer(DescriptionBlock);