import React from "react";

import TopCommonStat from "./TopCommonStat";
import { observer } from "mobx-react-lite";
import DescriptionBlock from "../InfoBlock/Description";
import { ReactComponent as PieNSVG } from '../../svg/pie-n.svg'
import Pie3dDetail from "../Diagrams/Pie3dDetail";
import useWindowDimensions from "../../utils/Hooks";
import appStore from "../../Store/MainStore";
import SNStatsDiagram from "../SNStats/SNStatsDiagram";
import LegendCategory from "./LegendCategory";
import InfoBlock from "../InfoBlock/InfoBlock";
import Pie3d from "../Diagrams/Pie3d";
import LegendMobile from "./LegendMobile";

const Category = ({
  className = '',
  verticalId = 0,
}) => {
  const { isPhone, isBigPhone, isBigPhoneNew } = useWindowDimensions();
  if (appStore.isLoading) {
    return null;
  }
  if (isPhone) {
    return (<div className={`bg`}>
    </div>)
  }

  return (<>
    <InfoBlock title="Категории каналов" isTwoRow className="verticals-block">

      {
        isBigPhone ?
        <div>
          <div className="top-content__categorybody">
            <div className="top-content__main-column top-content__pie">
              <Pie3dDetail key={`pie3d-${verticalId}`} verticalId={verticalId} />
              <div className="top-content-pie-data-back"></div>
              <div className="top-content-pie-data">
              </div>
            </div>
          </div>
          <div className="top-content__legends-mobile"><LegendMobile></LegendMobile></div>
          </div>
          :
          <div className="top-content__categorybody">
            <div className="top-content__category-legend" >
              <LegendCategory count={1} data={appStore.legendCategory.slice(0, 5)} > </LegendCategory>
            </div>
            <div className="top-content__main-column top-content__pie">
              <Pie3dDetail key={`pie3d-${verticalId}`} verticalId={verticalId} />
              <div className="top-content-pie-data-back"></div>
              <div className="top-content-pie-data">
              </div>
            </div>
            <div className="top-content__category-legend">
              <LegendCategory count={6} data={appStore.legendCategory.slice(5, 10)} ></LegendCategory>
            </div>
          </div>
      }
    </InfoBlock>
    </>
  )
}

export default observer(Category);

