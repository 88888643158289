import React from "react";
import { ReactComponent as WarningSVG } from '../../svg/warning.svg'

const NoDataMob = () => {
  return <div className="card-vertical__no-data">
    <WarningSVG />
    <div className="card-vertical__no-data-title">Данных пока нет</div>
    {/* <div className="card-vertical__no-data-info">Это новая вертикаль. Данные не успели сформироваться</div> */}
  </div>;
}

export default NoDataMob;