
import { ReactComponent as ChatSVG } from '../../svg/chat_stroke.svg';
import { ReactComponent as ERSVG } from '../../svg/err_stroke.svg';
import { ReactComponent as EyeSVG } from '../../svg/eye_stroke.svg';
import { ReactComponent as LikesSVG } from '../../svg/like_stroke.svg';
import ParameterIcon from "../Parameter/ParameterIcon";
import appStore from '../../Store/MainStore';
import { prettyNumber } from "../../utils/Formatter";
import { ReactComponent as ElmSVG } from '../../svg/commentelm.svg';



const Params = ({ type, value, postFix }) => {

  switch (type) {
    case 'coverage':
      return <div><EyeSVG />{prettyNumber(value)}</div>;
    case 'likes':
      return <div><LikesSVG />{prettyNumber(value)}</div>;
    case 'comments':
      return <div><ChatSVG />{prettyNumber(value)}</div>;
    // case 'share':
    //   return <div><ShareSVG />{prettyNumber(value)}</div>;
    case 'er':
      return <div><ERSVG />{prettyNumber(value)}%</div>;

    default:
      return <></>;
  };
}


const TopPlacement = ({ creative }) => {

  return (
    <>



      <div className="placement" onClick={() => window.open(creative.url, '_blank', 'noopener,noreferrer')}>
        <div className="placement__body">
          <ElmSVG className="placement__svg">
          </ElmSVG>
          <div className="placement__title">
            {creative.channel}
          </div>
          {creative.image ? <img className="placement__img" src={creative.image}>

          </img> : null}
          

          <div  className={ creative.image ? "placement__text" : "placement__text-noimg"}>
            {creative.text}
          </div>
          <div className="placement__footer">
            <div className="card-bordered__creatives-params">
              <Params type='coverage' value={creative.coverage} />
              <Params type='likes' value={creative.reactions} />
              <Params type='comments' value={creative.replies} />
              <Params type='er' value={creative.err} postFix="%" />
            </div>

          </div>
        </div>

      </div>



    </>
  )
}

export default TopPlacement;



