import React from "react";
import { observer } from 'mobx-react-lite';
import ReactTooltip from 'react-tooltip';

import appStore from "../../Store/MainStore";
import { prettyNumber } from "../../utils/Formatter";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const LegendCategory = ({data, count}) => {
  const navigate = useNavigate();

  return (<>
    {
      data.map((item, index) => (
        <div onClick={() => {
          if (item.coverage) {
            navigate(`/newsbreak/${item.id}`)
          } else {
            toast('Данные по вертикали в процессе сбора')
          }
        }} key={`legend-${item.id}`} className="top-content__legends-item">
          <div className="top-content__legends-num" style={{ backgroundColor: item.color }}>
            <div className="top-content__legends-inner">{index+count}</div>
          </div>
          <div className="top-content__legends-info">
            <div className="top-content__legends-name-category" data-tip={item.name}>{item.name}</div>
            <div className="top-content__legends-value">Охват: {prettyNumber(item.coverage)} ({prettyNumber(item.coveragePercent)}%)</div>
          </div>
          <ReactTooltip />
        </div>
      ))
      }
  </>); 
}

export default observer(LegendCategory);