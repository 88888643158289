import React from "react";
import { useNavigate } from 'react-router';
import { toast } from "react-toastify";
import useWindowDimensions from "../../utils/Hooks";
import LineCard from "../Diagrams/LineCard";
import ParameterIcon from "../Parameter/ParameterIcon";
import ParameterText from "../Parameter/ParameterText";
import ProgressBar from "../Progressbar/ProgressBar";
import Card from "./Card";
import NoData from "./NoData";
import { pluralWords, prettyNumberPostfix, prettyNumber } from "../../utils/Formatter";

import { observer } from 'mobx-react-lite';

import { ReactComponent as Lightning } from '../../svg/lightning.svg'
import { ReactComponent as Lightningmob } from '../../svg/lightningmob.svg'
import { LineChart } from "recharts";
import ParameterTextCoverage from "../Parameter/ParameterTextCoverage";
import appStore from "../../Store/MainStore";
import NoDataInfo from "./NoDataInfo";
import CardBordered from "./CardBordered";


const VerticalCard = ({
  vertical,
}) => {
  const navigate = useNavigate();
  const { isPhone, isBigPhoneNew } = useWindowDimensions();

  return (
    <>
  <div className="border-vertical">
    <Card

      className={`card-vertical ${vertical.type === 2 ? 'card-now' : ''}`}
      onClick={() => {
        if (vertical.coverageCount) {
          navigate(`/newsbreak/${vertical.id}`)
        } else {
          toast('Данные по вертикали в процессе сбора')
        }
      }}>
      {vertical.type === 2 ? <div className="card-now__ribbon-wrapper "> {isPhone ? <Lightningmob></Lightningmob> : <Lightning></Lightning>}</div> : null}

      <div>
        <div className="card__title">{vertical.name}</div>
      </div>
      {isBigPhoneNew ? null : <div className="card-vertical__date">{vertical.period}</div>}

      {
        vertical.coverageCount ?

          isBigPhoneNew ?
            
              <div className="card-vertical__body-phone">
                <div className="card-vertical__body">
                  <div className="card-vertical__diagram">
                    <LineCard data={vertical.history} isProgressHide={vertical.progress === null} />
                    {vertical.progress !== null ? <ProgressBar value={vertical.progress} maxValue={isPhone ? 125 : 173} /> : null}
                  </div>
                  <div className="card-vertical__params">
                    <div className="card-vertical__date">{vertical.period}</div>
                    <div className="display-row">
                      <ParameterText value={vertical.coverageCount} text="Охват" />
                      <ParameterText value={prettyNumberPostfix(vertical.publicCount, 0, 10000)} text={pluralWords(vertical.publicCount, ["Канал", "Канала", "Каналов"])} />
                    </div>
                    <div className="display-row">
                      <ParameterText value={vertical.postCount} text="Размещений" />
                      <ParameterText value={vertical.mean_coverage} text="Ср. охват" />
                    </div>
                  </div>
                </div>
                <div className="display-row card-vertical__params-second">
                  {vertical.erCount !== null ? <ParameterIcon key={`card-vertical-er`} param={{ value: vertical.erCount, icon: 'errinfo', size: 'medium' }} /> : null}
                  {vertical.commentCount !== null ? <ParameterIcon key={`card-vertical-comment`} param={{ value: vertical.commentCount, icon: 'comments', size: 'medium' }} /> : null}

                  {vertical.reactions !== null ? <ParameterIcon key={`card-vertical-like`} param={{ value: vertical.reactions, icon: 'likes', size: 'medium' }} /> : null}
                  {/* {vertical.shareCount !== null ? <ParameterIcon key={`card-vertical-share`} param={{ value: vertical.shareCount, icon: 'share', size: 'medium' }} /> : null} */}

                </div>

              </div>
              :
              
              <div className="card-vertical__body">

                <div className="card-vertical__diagram">

                  <LineCard data={vertical.history} isProgressHide={vertical.progress === null} />

                  {vertical.progress !== null ? <ProgressBar value={vertical.progress} maxValue={isPhone ? 125 : 173} /> : null}
                </div>
                <div className="card-vertical__params">
                  <ParameterTextCoverage className="parameter-text-row" value={vertical.coverageCount} text="Текущий охват"  ></ParameterTextCoverage>

                  {/* <ParameterText className="parameter-text-row"  value={vertical.coverageCount}  text="Текущий охват"    /> */}
                  <div className="display-row">
                    <ParameterText value={prettyNumberPostfix(vertical.publicCount, 0, 10000)} tooltip={vertical.publicCount > 10000 ? prettyNumber (vertical.publicCount) : ''} text={pluralWords(vertical.publicCount, ["Канал", "Канала", "Каналов"])} />
                    <ParameterText value={vertical.postCount} text="Размещений" />
                    <ParameterText value={vertical.mean_coverage} text="Ср. охват поста" />
                  </div>
                  <div className="display-row">
                    {vertical.erCount !== null ? <ParameterIcon key={`card-vertical-er`} param={{ value: vertical.erCount, icon: 'er', size: 'medium' }} /> : null}
                    {vertical.commentCount !== null ? <ParameterIcon key={`card-vertical-comment`} param={{ value: vertical.commentCount, icon: 'comments', size: 'medium' }} /> : null}
                    {vertical.reactions !== null ? <ParameterIcon key={`card-vertical-like`} param={{ value: vertical.reactions, icon: 'likes', size: 'medium' }} /> : null}



                    {/* {vertical.shareCount !== null ? <ParameterIcon key={`card-vertical-share`} param={{ value: vertical.shareCount, icon: 'share', size: 'medium' }} /> : null} */}

                  </div>
                </div>
              </div>

              : <div className="card-vertical__body ">
                <NoDataInfo />
              </div>
            
          

      }

    </Card>
    </div>
    </>
  );
}

export default observer(VerticalCard);