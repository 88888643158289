import React from "react";
import { ReactComponent as WarningSVG } from '../../svg/warning.svg'

const NoDataInfo = () => {
  return <div className="card-vertical__no-datainfo">
    <div className="card-vertical__no-data-title"><WarningSVG />Данных пока нет</div>
    <div className="card-vertical__no-data-info">Это новый инфоповод. Данные не успели сформироваться</div>
  </div>;
}

export default NoDataInfo;