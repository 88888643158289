
import { ReactComponent as TestSVG } from "../../svg/test_all.svg";

import appStore from '../../Store/MainStore';
import { ReactComponent as ElmSVG } from '../../svg/commentelm.svg';


const CreativeComment = () => {

  return (
    <>

      

      {
        appStore.topComents.map((comment, index) => (
            <div className="creativecomment " key={comment.id}>
              <div className="creativecomment__item" >
                <div className="creativecomment__allcomment">
                  <img className="creativecomment__avt" src={comment.user_image}>
                  </img>
                  <div className="creativecomment__body">
                    <ElmSVG className="creativecomment__svg">
                      </ElmSVG>
                    <div className="creativecomment__name">
                      {comment.user_name}
                    </div>
                    <div className="creativecomment__text">
                      {comment.text}
                    </div>
                  </div>
                </div>

              </div>
            </div>
        ))
      }
    </>
  )
}


export default CreativeComment;
