import React from "react";
import {
  Tooltip,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ComposedChart,
  Bar,
  LabelList,
} from "recharts";
import { dateFormat, prettyNumber, prettyNumberPostfix } from "../../utils/Formatter";
import useWindowDimensions from "../../utils/Hooks";

const renderCustomizedLabel = (props) => {
  const {
    x, y, width, height, value, color
  } = props;

  return (
    <g>
      <rect x={x} y={y} width={width} height="2" fill={color} />
    </g>
  );
};





function formatXAxis(tickItem) {
  return dateFormat(tickItem * 1000);
}

function formatYAxis(tickItem) {
  return prettyNumberPostfix(tickItem, 0, 1000);
}

const CustomTooltip = ({ active, payload, data, label }) => {
  if (active && payload && payload.length && label) {
    // const tt = data.filter(v => v.date === label);
    return (
      <div className="tooltip-sn-coverage">
        <div className="tooltip-sn-coverage__date">{dateFormat(label * 1000)}</div>
        {
          payload[1] ? payload[1].dataKey === 'coverage' ?
          <div className="tooltip-sn-coverage__coverage"><span>Охват</span>{prettyNumber(payload[1] ? payload[1].value : 0)}</div>
          :
          <div className="tooltip-sn-coverage__posts"><span>Посты</span>{prettyNumber(payload[1] ? payload[1].value : 0)}</div>
          : null
        }
        {
          payload[0] ? payload[0].dataKey === 'coverage' ?
          <div className="tooltip-sn-coverage__coverage"><span>Охват</span>{prettyNumber(payload[0] ? payload[0].value : 0)}</div>
          :
          <div className="tooltip-sn-coverage__posts"><span>Посты</span>{prettyNumber(payload[0] ? payload[0].value : 0)}</div>
          : null
        }
        {/* <div className="tooltip-sn-coverage__posts"><span>Посты</span>{prettyNumber(payload[0] ? payload[0].value : 0)}</div> */}
        {/* <div className="tooltip-sn-coverage__date">{dateFormat(tt[0].date * 1000)}</div>
        <div className="tooltip-sn-coverage__coverage"><span>Охват</span>{prettyNumber(tt[0].coverage)}</div>
        <div className="tooltip-sn-coverage__posts"><span>Посты</span>{prettyNumber(tt[0].postCount)}</div> */}
      </div>
    );
  }

  return null;
}

const StatsBar = ({
  dataDiagram
}) => {
  // return null;
  const { isPhone } = useWindowDimensions();

  return (
    <ResponsiveContainer width={isPhone ? '100%' : '110%'} height={isPhone ? 190 : 320}>
      <ComposedChart
        data={dataDiagram}
        margin={{left: 0, right: isPhone ? -10 : 60, top: 0}}
      >
        <CartesianGrid strokeDasharray="1 0" stroke="#484F66"  />
        <XAxis dataKey="date" dy={5} height={25} tickFormatter={formatXAxis} tick={{ fill: '#FFFFFF', fontSize: isPhone ? '10px' : '12px' }} />
        <YAxis yAxisId="left" allowDecimals={false}  type="number" domain={[dataMin => (dataMin - dataMin*0.01), 'auto' ]}   tick={{ fill: '#2994FF', fontSize: isPhone ? '10px' : '14px', paddingLeft: 50 }} tickFormatter={formatYAxis} width={isPhone ? 35 : 70} dx={isPhone ? 0 : -10} />
        <YAxis yAxisId="right"  allowDecimals={false} type="number" domain={[dataMin => (dataMin - dataMin*0.01), 'auto' ]}  orientation="right" tick={{ fill: '#9DFD27', fontSize: isPhone ? '10px' : '14px' }} dx={5} tickFormatter={formatYAxis} />
        {/* <YAxis axisLine={false} yAxisId="right" type="number" stroke="none" orientation="right" tick={stylesGraphic.YAxisRight.tick} /> */}
        <Tooltip content={<CustomTooltip data={dataDiagram} />} />
        <Bar cursor="pointer" name="Посты" yAxisId="right" dataKey="post_count" fill="#484F6666" barSize={isPhone ? 17 : 40}>
          <LabelList dataKey="name" content={(v) => renderCustomizedLabel({ ...v, color: '#9DFD27' })} />
        </Bar>
        <Line name="Охват" yAxisId="left" type="monotone" dataKey="coverage" stroke="#2994FF" cursor="pointer" strokeWidth="2" dot={{ fill: "#222632" }} />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default StatsBar;
