import { observer } from "mobx-react-lite";
import React from "react";
import Loader from "react-loader-spinner";
import appStore from "../../Store/MainStore";

const LoaderContainer = () => {

  return (
    <div className={`loader ${appStore.isLoading ? 'loader-show' : 'loader-hide'}`}>
      <Loader
        type="Audio" color="#FFF" height={80} width={80}
      />
    </div>
  )
}

export default observer(LoaderContainer);