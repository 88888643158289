import { observer } from "mobx-react-lite";
import React from "react";
import appStore from "../../Store/MainStore";
import ParameterIcon from "../Parameter/ParameterIcon";
import ParameterText from "../Parameter/ParameterText";

const StatData = () => {
  return (
    <>
      <div className="top-content__top">
        <ParameterText value={appStore.detailPage.snStats.snData[String(appStore.detailPage.snStats.activeTab)].publicCount} text="Площадок" />
        <ParameterText value={appStore.detailPage.snStats.snData[String(appStore.detailPage.snStats.activeTab)].postCount} text="Размещений" />
        <ParameterText value={appStore.detailPage.snStats.snData[String(appStore.detailPage.snStats.activeTab)].creativeCount} text="Креативов" />
      </div>
      <div className="top-content__line"></div>
      <ParameterText value={appStore.detailPage.snStats.snData[String(appStore.detailPage.snStats.activeTab)].coverageCount} text="Текущий охват" isBig />
      <div className="top-content__line"></div>
      <div className="top-content__bottom">
        {appStore.detailPage.snStats.snData[String(appStore.detailPage.snStats.activeTab)].likeCount !== null ? <ParameterIcon key={`top-stat-like`} param={{ value: appStore.detailPage.snStats.snData[String(appStore.detailPage.snStats.activeTab)].likeCount, icon: 'likes', size: 'small' }} /> : null}
        {appStore.detailPage.snStats.snData[String(appStore.detailPage.snStats.activeTab)].shareCount !== null ? <ParameterIcon key={`top-stat-share`} param={{ value: appStore.detailPage.snStats.snData[String(appStore.detailPage.snStats.activeTab)].shareCount, icon: 'share', size: 'small' }} /> : null}
        {appStore.detailPage.snStats.snData[String(appStore.detailPage.snStats.activeTab)].commentCount !== null ? <ParameterIcon key={`top-stat-comment`} param={{ value: appStore.detailPage.snStats.snData[String(appStore.detailPage.snStats.activeTab)].commentCount, icon: 'comments', size: 'small' }} /> : null}
        {appStore.detailPage.snStats.snData[String(appStore.detailPage.snStats.activeTab)].erCount !== null ? <ParameterIcon key={`top-stat-er`} param={{ value: appStore.detailPage.snStats.snData[String(appStore.detailPage.snStats.activeTab)].erCount, icon: 'er', size: 'small' }} /> : null}      
      </div>    
    </>
  );
}

export default observer(StatData);