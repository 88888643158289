import React from "react";


import { useNavigate, useParams } from "react-router";
import appStore from "../../Store/MainStore";

import { ReactComponent as ArrowSVG } from '../../svg/arrow_back.svg'




const InfoBlock = ({
  title = '',
  header = null,
  isTwoRow = false,
  className = '',
  btn = false,
  btndl = false,
  line = true,
  infotitle = false,
  infomob = false,
  children
}) => {
 const param = useParams();
  const navigator = useNavigate();

  

  return (<div className={`infoblock ${className}`}> 

    <div className={ `infoblock__header ${infomob ? 'infoblock__headermob' : ""} ${isTwoRow ? 'infoblock__header-two-row' : ''}, ${infotitle ? 'infoblock__header-info' : ''} ` } >
     {btn ? <div onClick={() => navigator ('/')} className="description-block__comeback"><ArrowSVG className="description-block__arrow"></ArrowSVG>Назад</div> : null}
      <div className="infoblock__title">{title}</div>
      <div className="infoblock__title-action">{header}</div>
      { btndl ?
       <div onClick={() => appStore.getCreativeDownload(title, param.id) } className="infoblock__title-btn">Скачать все размещения</div> : null }
    </div>
    {line ? <div className="infoblock__line"></div> : null }
    {children}
  </div>)
};

export default InfoBlock;

