import { observer } from "mobx-react-lite";
import React from "react";
import appStore from "../../Store/MainStore";
import useWindowDimensions from "../../utils/Hooks";
import InfoBlock from "../InfoBlock/InfoBlock";
import SNExample from "./SNExample";
import SliderExamples from "./SliderExample";

const SNExamplesBlock = () => {
  const { isPhone } = useWindowDimensions();

  if (appStore.detailPage.examples && appStore.detailPage.examples.length) {
    return <InfoBlock title="Примеры размещений">
      {
        isPhone ? <SliderExamples />
        :
        <div className="cards__example">
          { appStore.detailPage.examples.map(item => <SNExample key={`example-link-${item.shortName}`} item={item} />)}
        </div>
      }
    </InfoBlock>;
  }

  return null;
};

export default observer(SNExamplesBlock);