export const prettyNumber = (value) => {
  return (value || 0).toLocaleString(undefined, { maximumFractionDigits: 2 });
}

export const dateFormat = (date) => {
  const _date = new Date(date);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  }
  const formatted = _date.toLocaleDateString('ru-RU', options)
  return formatted;
}

const nFormatter = (num, digits) => {
  const si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "K" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "ММ" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return prettyNumber(num / si[i].value).replace(rx, "$1") + si[i].symbol;
};

export const prettyNumberPostfix = (num, digits, maxNumToPostfix) => {
  if (!num) {
    return num;
  }
  const floatValue = parseFloat(num);
  if (!isNaN(floatValue) && floatValue < 1000) {
    return prettyNumber(floatValue);
  }

  maxNumToPostfix = maxNumToPostfix || 10000;
  if (num < maxNumToPostfix) {
    return prettyNumber(num);
  }
  return nFormatter(num, digits);
};

export const removeSpaces = v => typeof v === 'string' ? v.replace(/\s+/g, '') : v;

export const pluralWords = (n, textForm) => {
  const _n = Math.abs(removeSpaces(n)) % 100
  const n1 = _n % 10
  if (_n > 10 && _n < 20) { return textForm[2]; }
  if (n1 > 1 && n1 < 5) { return textForm[1]; }
  if (n1 == 1) { return textForm[0]; }
  return textForm[2];
}