import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import appStore from "../../Store/MainStore";
import useWindowDimensions from "../../utils/Hooks";
import InfoBlock from "../InfoBlock/InfoBlock";
import StatsDiagram from "./SNStatsDiagram";
import StatData from "./StatData";

import { ReactComponent as VKSVG } from '../../svg/sn-vk.svg'
import { ReactComponent as OKSVG } from '../../svg/sn-ok.svg'
import { ReactComponent as TgSVG } from '../../svg/sn-tg.svg'
import { ReactComponent as YtSVG } from '../../svg/sn-yt.svg'
import { ReactComponent as FbSVG } from '../../svg/sn-fb.svg'
import { ReactComponent as ZenSVG } from '../../svg/sn-zen.svg'
import { ReactComponent as OverSVG } from '../../svg/sn-over.svg'
import { ReactComponent as InstaSVG } from '../../svg/sn-insta.svg'
import { ReactComponent as TiktokSVG } from '../../svg/sn-tiktok.svg'

const SNIcon = ({ shortName, className }) => {
  switch (shortName) {
    case 'vk':      
      return <VKSVG className={className} />;
    case 'ok':      
      return <OKSVG className={className} />;
    case 'tg':      
      return <TgSVG className={className} />;
    case 'yt':      
      return <YtSVG className={className} />;
    case 'fb':      
      return <FbSVG className={className} />;
    case 'zen':      
      return <ZenSVG className={className} />;
    case 'over':      
      return <OverSVG className={className} />;
    case 'insta':      
      return <InstaSVG className={className} />;
    case 'tiktok':      
      return <TiktokSVG className={className} />;
  
    default:
      return <></>;
  }
}

const TabsComponent = observer(() => {
  const { isPhone } = useWindowDimensions();

  return (
    <div className="infoblock__header-tab">
      {
        appStore.detailPage.snStats.snTabs.map((item) => (
          <div 
            key={`filter-${item.id}`}
            className={`infoblock__header-tab-item ${appStore.detailPage.snStats.activeTab === item.id ? 'infoblock__header-tab-item_active' : ''}`}
            onClick={() => { 
              appStore.detailPage.snStats.activeTab = item.id; 
            }}
          >
            {isPhone ? <SNIcon className={`infoblock__header-tab-item-icon ${appStore.detailPage.snStats.activeTab === item.id ? 'infoblock__header-tab-item-icon_active' : ''}`} shortName={item.shortName} /> : item.title.split('').map((v, index) => (v === ' ' ? <div key={`${item.id}-${index}-stat`}>&nbsp;</div> : <div key={`${item.id}-${index}-stat`}>{v}</div>))}
          </div>))
      }
    </div>
  );
})

const Header = () => {
  return (<div className="infoblock__header">
    <div className="infoblock__header-tab">
      <TabsComponent />
    </div>
  </div>)
}
 
const SNStatsBlock = () => {
  if (appStore.detailPage.snStats.snTabs && appStore.detailPage.snStats.snTabs.length) {
    return <InfoBlock title="Статистика" className="infoblock__statistics" header={<Header />}>
      <div className="snstats">
        <StatsDiagram />
        <div className="snstats__data">
          <StatData />
        </div>
      </div>
    </InfoBlock>;
  }

  return null;
}

export default observer(SNStatsBlock);