import React from "react";

import Legend from "./Legend";
import TopCommonStat from "./TopCommonStat";
import Pie3d from '../Diagrams/Pie3d';
import { pluralWords } from "../../utils/Formatter";
import { observer } from "mobx-react-lite";
import appStore from "../../Store/MainStore";
import useWindowDimensions from "../../utils/Hooks";
import LegendMobile from "./LegendMobile";

const ContentTop = ({
  isLegend = false,
  className = '',
  pagethemeblock = false,
}) => {
  const { isPhone, isBigPhone} = useWindowDimensions();


  return (<div className={`top-content bg ${className}`}>
    {
      isBigPhone ?
        <div className="container">
          <div className="top-content__main">
            <div className="top-content__main-column top-content__pie">
              <Pie3d isPhone />
              <div className="top-content-pie-data-back"></div>
              <div className="top-content-pie-data">
                <div className="top-content-pie-data__now">
                  {appStore.commonStats.verticalsNow}
                </div>
                {/* <div className="top-content-pie-data__num">{appStore.commonStats.themeBlockAll}</div> */}
                { pagethemeblock ? <div className="top-content-pie-data__num">{appStore.themeBlockAll}</div> : <div className="top-content-pie-data__num">{appStore.infoBlockAll}</div> }
                { pagethemeblock ?  <div className="top-content-pie-data__theme">{pluralWords(appStore.themeBlockAll, ['тематический', 'тематические', 'тематических'])}</div>  : <div className="top-content-pie-data__theme">{pluralWords(appStore.infoBlockAll, ['инфоповод', 'инфоповода', 'инфоповодов'])}</div> }
                { pagethemeblock ? <div className="top-content-pie-data__vertical">{pluralWords(appStore.themeBlockAll, ['блок', 'блоки', 'блоков'])}</div> : null}
              </div>
            </div>
          </div>
          <div className="top-content__legends-mobile"><LegendMobile /></div>
          <div className="">
            <TopCommonStat />
          </div>
        </div>
        : 
        <div className="container">
          <div className="top-content__main">
            <div className="top-content__main-column top-content__pie">
              {/* <PieDiagram /> */}
              <Pie3d />
              <div className="top-content-pie-data-back"></div>
              <div className="top-content-pie-data">
                {/* <div className="top-content-pie-data__now">
                {appStore.commonStats.verticalsNow}
              </div> */}
               { pagethemeblock ? <div className="top-content-pie-data__num">{appStore.themeBlockAll}</div> : <div className="top-content-pie-data__num">{appStore.infoBlockAll}</div> }
               { pagethemeblock ? <div className="top-content-pie-data__theme">{pluralWords(appStore.themeBlockAll, ['тематический', 'тематическиx', 'тематических'])}</div> : <div className="top-content-pie-data__theme">{pluralWords(appStore.infoBlockAll, ['инфоповод', 'инфоповода', 'инфоповодов'])}</div> }
               { pagethemeblock ? <div className="top-content-pie-data__vertical">{pluralWords(appStore.themeBlockAll, ['блок', 'блока', 'блоков'])}</div> : null }
              </div>
            </div>
            <div className="top-content__main-column">
              <TopCommonStat />
            </div>
          </div>
          {isLegend && <div className="top-content__legends"><Legend /></div>}
        </div>

     }
  </div>)
}

export default observer(ContentTop);