import { Html, OrbitControls } from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DoubleSide, Path, Shape, CircleGeometry } from "three";
import appStore from "../../Store/MainStore";
import { prettyNumber } from "../../utils/Formatter";

const outerRadius = 20;
const innerRadius = 10;
const tipPoint = (outerRadius - innerRadius) / 2 + innerRadius;

const Slice = ({
  color,
  startAngle = 0,
  endAngle = 0,
  height,
  data,
  isAnimateSlice = false,
  onMouseEnter = () => {},
  onClick = () => {},
  changeHover = () => {},
}) => {
  const ref = useRef(null);
  const [endAg, setEndAg] = useState(0);
  const [isAnimate, setIsAnimate] = useState(isAnimateSlice);
  const [heightA, setHeightA] = useState(height);
  const [_ir, setIr] = useState(innerRadius);
  const [_outr, setOutr] = useState(outerRadius);

  useFrame(() => {
    if (isAnimate) {
      if (endAg < data.endLengthSector) {
      // if (endAg < endAngle) {
        setEndAg(endAg + 0.7);
      } else {
        setEndAg(data.endLengthSector);
        setIsAnimate(false);
      }
    }
    // if (isAnimate) {

    //   if (_outr < outerRadius) {
    //     setOutr(_outr+1);
    //   } else {
    //     if (_ir < innerRadius) {
    //       setOutr(outerRadius);
    //       setIr(_ir + 1);
    //     } else {
    //       setIr(innerRadius);
    //       setIsAnimate(false);
    //     }
    //   }
    // }
  });

  const arcShape = useMemo(() => {
    const shape = new Shape()
      // .moveTo(0, 0)
      // .absarc(0, 0, _outr, 0, data.endLengthSector, false)
      // .absarc(0, 0, _outr, startAngle, endAg, false )
      .absarc(0, 0, _outr, 0, endAg, false)
      .lineTo(0, 0);
    // shape.closePath();

    const _hole = new Path()
      // .moveTo(0, 0)
      // .absarc(0.01, 0.001, _ir, 0, data.endLengthSector, false)
      // .absarc(0, 0, _ir, startAngle, endAg, false)
      .absarc(endAg > Math.PI ? 0 : 0.0001, endAg > Math.PI ? 0 : 0.001, _ir, 0, endAg, false)
      .lineTo(endAg > Math.PI ? 0 : 0.1, endAg > Math.PI ? 0 : 0.001);
      // .absarc(0.01, 0.001, _ir, 0, endAg, false)
      // .lineTo(0.01, 0.001);
    // _hole.closePath();

    shape.holes.push(_hole);
    return shape;
  }, [_outr, _ir, endAg]);

  // const arcShape = useMemo(() => {
  //   const shape = new Shape()
  //     // .moveTo(0, 0)
  //     // .absarc(0, 0, _outr, startAngle, endAg, false )
  //     .absarc(0, 0, _outr, 0, endAg, false )
  //     .lineTo(0, 0);
      
  //   // shape.closePath();

  //   const _hole = new Path()
  //     // .moveTo(0, 0)
  //     // .absarc(0.01, 0.001, _ir, startAngle, endAg, false)
  //     .absarc(0.1, 0.001, _ir, 0, endAg, false)
  //     .lineTo(0.1, 0.001);
  //   // _hole.closePath();

  //   shape.holes.push(_hole);
  //   return shape;
  // }, [_outr, _ir, endAg]);
  // }, [_outr, _ir, startAngle, endAg]);

  const extrudeSettings = {
    depth: heightA,
    bevelEnabled: false,
    bevelThickness: 1,
    bevelSize: 1,
    bevelOffset: 0,
    bevelSegments: 30,
    steps: 1,
    curveSegments: 60,
  }

  return (
    <mesh
      ref={ref}
      receiveShadow 
      castShadow 
      onMouseEnter={onMouseEnter} 
      onClick={onClick} 
      onPointerOver={(event) => {
        changeHover(true, data.id);
      }}
      onPointerOut={(event) => {
        changeHover(false, data.id);
      }}
      position={[0, 0, 20]}
      rotation={[0, 0, startAngle]}
    >
      <extrudeBufferGeometry attach="geometry" args={[arcShape, extrudeSettings]} />
      <meshStandardMaterial color={color} side={DoubleSide} />
    </mesh>
  )
}

const Slices = ({ verticalId, dataSlices, navigate, handleHover }) => {
  const changeCursor = useCallback((isHover) => {
    // document.body.style.cursor = isHover ? 'pointer' : 'auto'
  }, []);

  return (
    <>
      {
        dataSlices.map((data, index) => (
          data.coveragePercent && <Slice 
            key={'pie-'+verticalId+'-d-'+data.id} 
            color={data.color} 
            startAngle={data.startAngle} 
            endAngle={data.lengthSector} 
            height={data.height} 
            data={data}
            isAnimateSlice={true}
            onClick={() => {handleHover(data.id)}}
            changeHover={(isHover, num) => {changeCursor(isHover); handleHover(num)}}
          />))
      }
    </>
  )
}

const Tip = observer(({ data }) => {
  if (appStore.commonTip.isShow && appStore.commonTip.num === data.id) {
  // if (true) {
    return (
      <Html position={[data.coords.x, data.coords.y, 20]} scaleFactor={1} className="too" >
        <div className="tooltip-pie">
          <div className="tooltip-pie__num tooltip-pie__num-block-icon">
            <div className={`tooltip-pie__num tooltip-pie`}><span>#</span>{+data.num + 1}</div>
          </div>
          <div className="tooltip-pie__value">
            {prettyNumber(data.coveragePercent)}%
            <div className={`tooltip-pie__pointer ${data.coords.x > 0 ? 'tooltip-pie__pointer-left' : ''}`}></div>
          </div>
        </div>
      </Html>
    )
  }
  return null;
})

const Tips = ({ data }) => {
  const _data = useMemo(() => {
    const tipData = data.map(v => {
      const currAngle = (v.lengthSector - v.startAngle) / 2 + v.startAngle;    
      const xP = tipPoint * Math.cos(currAngle) - 5.5;
      const yP = tipPoint * Math.sin(currAngle) + 5.5;

      return ({ ...v, coords: { x: xP, y: yP } })
    });

    return tipData;
  }, [data])

  return _data.map(v => <Tip key={`tip-common-${v.id}`} data={v} />)
}

const Pie3d = ({ verticalId }) => {

  return (
    <div
      className="pie-diagram"
      onPointerOut={() => appStore.handleCommonTip(false, 0)}
    >
      {
        appStore.detalPie && appStore.detalPie.length ?
        <Canvas
          pixelRatio={window.devicePixelRatio}
          flat
          camera={{
            position: [0, 0, 50],
          }}
          shadows
          gl={{
            antialias: true,
          }}
          mode="concurrent"
        >
          {/* <OrbitControls /> */}
          <ambientLight intensity={1} />  
          <directionalLight
              intensity={0.3}
              castShadow
              position={[0, 0, 100]}
              color={'#ffffff'}
              shadow-mapSize-width={2048}
              shadow-mapSize-height={2048}
            />
            <pointLight 
              intensity={0.8}
              position={[-20, 20, 100]}
            />
            <Slices verticalId={verticalId} dataSlices={appStore.detalPie} handleHover={(num) => appStore.handleCommonTip(true, num)} />
            <Tips data={appStore.detalPie} />
        </Canvas>

        : null
      }
    </div>
  )
}

export default observer(Pie3d);