import React, { useCallback, useEffect } from "react";

import appStore from "../../Store/MainStore";
import { observer } from 'mobx-react-lite';


import { ReactComponent as LogoSVG } from '../../svg/newlogo.svg';
import { ReactComponent as PhoneSVG } from '../../svg/phone.svg';

const Header = ({
  title = '',
}

) => {



  const fetch = useCallback(async () => {
    const res = await appStore.getUserName();

  }, []);

  useEffect(() => {
    if (appStore.setToken) {
      fetch()
    }
    document.body.style.cursor = 'auto';
  }, [appStore.authToken]);







  return (<div className="main__header container">

    {/* <LogoSVG className="main__header-logo" onClick={() => window.location.href = '/'} /> */}
    <div className="container main__header-wrapper">



      <div className="main__header-title">
        {appStore.userName.name}
      </div>



      <div className="main__header-titleblock">
        {title}
      </div>
      {/* <div className="main__header-contact">
        <div className="main__header-contact-phone"><a href="tel:+74956486941">+7 (495) 648 69 41</a></div>
        <div className="main__header-contact-email"><a href="mailto:hello@nativno.com">hello@nativno.com</a></div>
        <div className="main__header-contact-phone-image"><a href="tel:+74956486941"><PhoneSVG /></a></div>
      </div>    */}
    </div>
  </div>)


}

export default observer(Header);