import React from "react";
import CardBordered from "./CardBordered";

const Card = ({
  className = '',
  children,
  onClick = () => {},
}) => {
  return (
  
    <div
      className={`card ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
   
  );
}

export default Card;