import React from "react";
import CardBordered from "../Card/CardBordered";
import AuditoryBar from "../Diagrams/AuditoryBar";

const AuditoryGender = ({ title, data, cnPostfix }) => {
  return (
    <CardBordered className={`card-bordered__auditory card-bordered__auditory-gender`}>
      <div className="card-bordered__dbl-title">
        <div className="card-bordered__title">
          {title}
        </div>
        <div className="card-bordered__dbl-title-legend">
          <div className="card-bordered__dbl-title-legend-f"><div className="card-bordered__dbl-title-legend-fblock"></div>Женщины</div>
          <div className="card-bordered__dbl-title-legend-s"><div className="card-bordered__dbl-title-legend-sblock"></div>Мужчины</div>
        </div>
      </div>
      <AuditoryBar dataDiagram={data} />     
    </CardBordered>
  );
}

export default AuditoryGender;