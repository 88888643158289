import React from "react";
import { prettyNumber } from "../../utils/Formatter";
import useWindowDimensions from "../../utils/Hooks";
import ProgressBar from "../Progressbar/ProgressBar";

const AuditoryItem = ({ item, classNamePostfix = '' }) => {
  const { isPhone } = useWindowDimensions();

  return (
    <div className={`auditory__item`}>
      <div className="auditory__item-top">
        <div className="auditory__item-name" data-tip={item.title}>{item.title}</div>
        <div className="auditory__item-value">{prettyNumber(item.percent)}%</div>
      </div>
      <div><ProgressBar className={`progress${classNamePostfix}`} value={item.decimal} isHideValue maxValue={isPhone ? 110 : 212} /></div>
    </div>
  );
}

export default AuditoryItem;