import React from "react";
import { observer } from 'mobx-react-lite';
import appStore from "../../Store/MainStore";
import VerticalCard from './VerticalCard';

const VerticalCards = () => {
  return <div className="vertical-cards display-row">
    {
      appStore.cmVerticals.map(vertical => <VerticalCard key={`vertical-card-${vertical.id}`} vertical={vertical} />)
    }
  </div>;
}

export default observer(VerticalCards);