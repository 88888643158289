import React from "react";
import ReactTooltip from "react-tooltip";
import { prettyNumber } from "../../utils/Formatter";

const ParameterText = ({
  value,
  text,
  isBig = false,
  className = '',
  tooltip = '',
  
}) => {
  return ( 
    
    <>
     <div className={`parameter-text ${className}` }>
        <div data-tip={tooltip} className={`parameter-text__value ${isBig ? 'parameter-text__value-big' : ''}`}>{prettyNumber(value)}  </div>
        <div className={`parameter-text__text ${isBig ? 'parameter-text__text-big' : ''}`}>{text}</div> 
      </div> 
      {tooltip  ? <ReactTooltip></ReactTooltip> : null}
    </>
    
  );
}

export default ParameterText;